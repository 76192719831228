import type { FC } from "react";

export const IconSelectedNonChecked: FC<React.HTMLProps<SVGSVGElement>> = (
	props,
) => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<circle cx="14" cy="14" r="13" stroke="white" strokeWidth="2" />
		</svg>
	);
};
