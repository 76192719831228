import { Button, Title } from "@telegram-apps/telegram-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigation } from "../../hooks/useLocation";
import IconChevron from "../../icons/IconChevron";
import IconDeny from "../../icons/IconDeny";
import { UploadInstructionItem } from "./UploadInstructionItem";

import wontwork1 from "../../icons/wontwork1.webp";
import wontwork2 from "../../icons/wontwork2.webp";
import wontwork3 from "../../icons/wontwork3.webp";
import wontwork4 from "../../icons/wontwork4.webp";
import wontwork5 from "../../icons/wontwork5.webp";
import wontwork6 from "../../icons/wontwork6.webp";

import useStore from "../../hooks/useStore";
import workbest1 from "../../icons/workbest1.webp";
import workbest2 from "../../icons/workbest2.webp";
import workbest3 from "../../icons/workbest3.webp";
import workbest4 from "../../icons/workbest4.webp";
import workbest5 from "../../icons/workbest5.webp";
import workbest6 from "../../icons/workbest6.webp";
import { FooterButton } from "../../components/FooterButton";
import { hapticClick } from "../../utils/haptic";

interface UploadInstructionProps {
	styleId?: number;
	category?: number;
	two_photo?: boolean;
	style?: "clean" | "funny";
}

const UploadInstruction: React.FC<UploadInstructionProps> = (props) => {
	const navigation = useNavigation();
	const { setTabbarVisible } = useStore();
	const { t } = useTranslation();

	const handleUploadClick = () => {
		hapticClick("light");
		navigation.push("/discover/instruction/picker", {
			styleId: props.styleId,
			category: props.category,
			two_photo: props.two_photo,
			style: props.style,
		});
	};

	React.useEffect(() => {
		setTabbarVisible(false);

		return () => {
			setTabbarVisible(true);
		};
	}, [setTabbarVisible]);

	return (
		<div
			className="container"
			style={{
				maxHeight: "calc(100vh - var(--safe-area-inset-bottom))",
				padding: "16px 0",
			}}
		>
			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					paddingBottom: 62,
				}}
			>
				<div className="flex flex-col gap-[24px]">
					<Title
						level="3"
						weight="1"
						style={{
							textAlign: "center",
							padding: "0 16px",
							marginTop: "47px",
						}}
					>
						{t("uploadInstruction.title.line1")}
						<br />
						{t("uploadInstruction.title.line2")}
					</Title>
					<UploadInstructionItem
						icon={<IconChevron />}
						title={t("uploadInstruction.workBest.title")}
						subtitle={t("uploadInstruction.workBest.subtitle")}
						images={[
							workbest1,
							workbest2,
							workbest3,
							workbest4,
							workbest5,
							workbest6,
						]}
					/>

					<UploadInstructionItem
						icon={<IconDeny />}
						title={t("uploadInstruction.wontWork.title")}
						subtitle={t("uploadInstruction.wontWork.subtitle")}
						images={[
							wontwork1,
							wontwork2,
							wontwork3,
							wontwork4,
							wontwork5,
							wontwork6,
						]}
					/>
				</div>

				<FooterButton onClick={handleUploadClick}>
					<div className="text-white font-medium">
						{t("uploadInstruction.understand")}
					</div>
				</FooterButton>
			</div>
		</div>
	);
};

export default UploadInstruction;
