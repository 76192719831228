import { useIntersectionObserver } from "usehooks-ts";
import CachedImage from "../CachedImage/CachedImage";
import type { GalleryItem, GalleryItemPhoto } from "../../api/types";
import { useEffect, useRef } from "react";
import { useGalleryScrollStore } from "../../hooks/useGalleryScrollStore";

interface LazyGalleryProps {
	model: GalleryItem;
	handleOpenViewer: (model: GalleryItem, index: number) => void;
}

export const LazyGallery = ({ model, handleOpenViewer }: LazyGalleryProps) => {
	const { isIntersecting, ref } = useIntersectionObserver({
		threshold: 0,
	});
	const containerRef = useRef<HTMLDivElement>(null);
	const { setScrollPosition, getScrollPosition } = useGalleryScrollStore();

	// Save scroll position when user scrolls
	const handleScroll = () => {
		if (containerRef.current) {
			setScrollPosition(model.id, containerRef.current.scrollLeft);
		}
	};

	// Restore scroll position when gallery becomes visible
	useEffect(() => {
		if (isIntersecting && containerRef.current) {
			const savedScroll = getScrollPosition(model.id);
			if (savedScroll) {
				containerRef.current.scrollLeft = savedScroll;
			}
		}
	}, [isIntersecting, model.id, getScrollPosition]);

	return (
		<div ref={ref} className="h-[150px]">
			{isIntersecting && (
				<div 
					ref={containerRef}
					className="carousel-container px-[16px]"
					onScroll={handleScroll}
				>
					{model.result?.map((item: GalleryItemPhoto, index: number) => {
						const isSticker = model.style.type === "meme";

						return (
							<CachedImage
								className="image-container"
								key={`${model.id}-${index}`}
								src={item.url}
								alt={`Image ${item.url}`}
								style={{
									minWidth: "100px",
									height: "150px",
									borderRadius: isSticker ? "0" : "10px",
									overflow: "hidden",
									objectFit: isSticker ? "contain" : "cover",
									cursor: "pointer",
								}}
								onClick={() => handleOpenViewer(model, index)}
								threshold={100}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};
