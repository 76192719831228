import React from "react";

export const IconAdd: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg
			width="15"
			height="16"
			viewBox="0 0 15 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.49961 0.314453C7.99667 0.314453 8.39961 0.717397 8.39961 1.21445V6.81445H13.9996C14.4967 6.81445 14.8996 7.2174 14.8996 7.71445C14.8996 8.21151 14.4967 8.61445 13.9996 8.61445H8.39961V14.2145C8.39961 14.7115 7.99667 15.1145 7.49961 15.1145C7.00255 15.1145 6.59961 14.7115 6.59961 14.2145V8.61445H0.999609C0.502553 8.61445 0.0996094 8.21151 0.0996094 7.71445C0.0996094 7.2174 0.502553 6.81445 0.999609 6.81445H6.59961V1.21445C6.59961 0.717397 7.00255 0.314453 7.49961 0.314453Z"
				fill="white"
			/>
		</svg>
	);
};
