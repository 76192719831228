import { Headline } from "@telegram-apps/telegram-ui";
import { useEffect, useState } from "react";
import CachedImage from "../../components/CachedImage/CachedImage";
import useStore from "../../hooks/useStore";
import { IconSelectedChecked } from "../../icons/IconSelectChecked";
import { IconSelectedNonChecked } from "../../icons/IconSelectNonChecked";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import { useNavigation } from "../../hooks/useLocation";
import { FooterButton } from "../../components/FooterButton";
import { useGalleryStore } from "./useGalleryStore";
import type { GalleryItemPhoto } from "../../api/types";
import { hapticClick } from "../../utils/haptic";
import { useSafeAreaBottom } from "../../utils/safeArea";

interface StickerPickerProps {
	taskId: number;
}

export const StickerPicker = (props: StickerPickerProps) => {
	const [selectedSticker, setSelectedSticker] = useState<GalleryItemPhoto[]>(
		[],
	);

	const { items, fetchGallery, selectedItems, setSelectedItems } =
		useGalleryStore();

	const filteredItems = items.filter((item) => item?.style?.type !== "photo");

	const { setTabbarVisible } = useStore();
	const safeAreaBottom = useSafeAreaBottom();

	const [isButtonVisible, setIsButtonVisible] = useState(false);

	const navigation = useNavigation();

	const { currentLocation } = navigation;

	const { t } = useTranslation();

	useEffect(() => {
		fetchGallery();
	}, [fetchGallery]);

	useEffect(() => {
		if (
			selectedSticker.length > 0 &&
			currentLocation?.pathname === "/stickers/picker"
		) {
			setIsButtonVisible(true);
		} else {
			setIsButtonVisible(false);
		}

		return () => {
			setIsButtonVisible(false);
		};
	}, [selectedSticker, currentLocation]);

	useEffect(() => {
		setTabbarVisible(false);

		return () => {
			setTabbarVisible(true);
		};
	}, [setTabbarVisible]);

	useEffect(() => {
		console.log("selectedItems", selectedItems);
		if (selectedItems.length > 0) {
			setSelectedSticker(selectedItems);
			return;
		}

		const taskItem = filteredItems.find((item) => item.id === props.taskId);

		if (taskItem) {
			setTimeout(() => {
				const element = document.getElementById(`task-${taskItem.id}`);
				if (element) {
					element.scrollIntoView({ behavior: "instant" });
				}
			}, 100);
		}

		const stickers = taskItem?.result.map((sticker) => ({
			photo_id: sticker.photo_id,
			url: sticker.url,
		}));

		if (stickers) {
			setSelectedSticker(stickers);
		}
	}, [filteredItems, props.taskId, selectedItems]);

	useEffect(() => {
		if (selectedSticker.length > 0) {
			setSelectedItems(selectedSticker);
		}
	}, [selectedSticker, setSelectedItems]);

	if (filteredItems.length === 0) {
		return null;
	}

	const handleSelectSticker = (sticker: GalleryItemPhoto) => {
		hapticClick("light");

		setSelectedSticker((prev) => {
			if (prev.some((s) => s.photo_id === sticker.photo_id)) {
				return prev.filter((s) => s.photo_id !== sticker.photo_id);
			}
			return [...prev, sticker];
		});
	};

	return (
		<div className="container enable-scrollbar">
			<div
				className="flex flex-col gap-[16px]"
				style={{
					marginBottom: isButtonVisible ? 62 + safeAreaBottom : 0,
				}}
			>
				{filteredItems.map(({ id, style, result }) => (
					<div key={id} id={`task-${id}`}>
						<Headline
							weight="2"
							className="flex items-center justify-between gap-[2px]"
						>
							{style.name}
						</Headline>
						<div className="grid grid-cols-3 grid-rows-1 justify-items-center gap-4 mt-[8px]">
							{result.map((sticker) => {
								const isSelected = selectedSticker.some(
									(s) => s.photo_id === sticker.photo_id,
								);

								return (
									// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
									<div
										key={sticker.photo_id}
										className="relative w-[100px] h-[150px]"
										onClick={() => handleSelectSticker(sticker)}
									>
										<CachedImage
											className="image-container"
											key={sticker.photo_id}
											src={sticker.url}
											alt={`Image ${sticker.url}`}
											style={{
												width: "100px",
												height: "150px",
												borderRadius: "10px",
												overflow: "hidden",
												objectFit: "cover",
												cursor: "pointer",
											}}
											threshold={100}
										/>
										<div className="absolute bottom-[10px] right-[10px]">
											{isSelected ? (
												<IconSelectedChecked />
											) : (
												<IconSelectedNonChecked />
											)}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				))}
			</div>

			{isButtonVisible && (
				<FooterButton
					onClick={() => {
						navigation.push("/stickers/pack", {
							taskId: props.taskId,
							stickers: selectedSticker,
						});
					}}
				>
					<div className="flex items-center justify-center gap-[9px] text-white">
						<span className="text-[15px] font-medium">
							{t("stickerPack.continue")}
						</span>
						<div
							className={clsx(
								"bg-white text-button rounded-[10px] min-w-[20px] min-h-[20px] text-[15px] font-medium",
								"leading-[20px] tracking-[-0.23px] text-center",
								"pr-[1px]",
								selectedSticker.length > 9 && "min-w-[30px]",
							)}
						>
							{selectedSticker.length}
						</div>
					</div>
				</FooterButton>
			)}
		</div>
	);
};

export default StickerPicker;
