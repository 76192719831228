import { Tabbar } from "@telegram-apps/telegram-ui";
import type { FC } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useStore from "../../hooks/useStore";
import IconGallery28 from "../../icons/IconGallery28";
import IconGame28 from "../../icons/IconGame28";
import IconSmile28 from "../../icons/IconSmile28";
import { TabType } from "../../types/tabs";
import { hapticClick } from "../../utils/haptic";
import { useSafeAreaBottom } from "../../utils/safeArea";
import WebApp from "@twa-dev/sdk";

const TabBar: FC = () => {
	const { activeTab, setActiveTab } = useStore();
	const { t } = useTranslation();

	const paddingBottom = useSafeAreaBottom();

	const tabs = [
		{ id: TabType.STICKERS, label: t("tabBar.stickers") },
		{ id: TabType.DISCOVER, label: t("tabBar.avatars") },
		{ id: TabType.PROFILE, label: t("tabBar.gallery") },
	];

	const updateTabBarHeight = useCallback(() => {
		const tabBarHeight = document.querySelector("#tabBar")?.clientHeight || 32;
		document.documentElement.style.setProperty(
			"--tab-bar-height",
			`${tabBarHeight}px`,
		);
	}, []);

	useEffect(() => {
		window.addEventListener("resize", updateTabBarHeight);

		WebApp.onEvent("viewportChanged", updateTabBarHeight);
		setTimeout(updateTabBarHeight, 1000);

		return () => {
			WebApp.offEvent("viewportChanged", updateTabBarHeight);
			window.removeEventListener("resize", updateTabBarHeight);
		};
	}, [updateTabBarHeight]);

	return (
		<Tabbar
			id={"tabBar"}
			style={{
				paddingBottom: `${paddingBottom}px`,
				backgroundColor: "var(--tg-theme-bg-color)",
				borderTop: "0.33px solid rgba(0, 0, 0, 0.15)",
			}}
		>
			{tabs.map((tab) => (
				<Tabbar.Item
					key={tab.id}
					text={tab.label}
					selected={tab.id === activeTab}
					onClick={() => {
						hapticClick("medium");
						setActiveTab(tab.id);
					}}
				>
					{tab.id === TabType.DISCOVER && <IconGame28 />}
					{tab.id === TabType.STICKERS && <IconSmile28 />}
					{tab.id === TabType.PROFILE && <IconGallery28 />}
				</Tabbar.Item>
			))}
		</Tabbar>
	);
};

export default TabBar;
