import { Input, Spinner } from "@telegram-apps/telegram-ui";
import { Headline } from "@telegram-apps/telegram-ui/dist/components/Typography/Headline/Headline";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "./StickerPack.css";
import CachedImage from "../../components/CachedImage/CachedImage";
import { createSwapy } from "swapy";
import type { Swapy } from "swapy";
import clsx from "clsx";
import useStore from "../../hooks/useStore";
import { FooterButton } from "../../components/FooterButton";
import { useGalleryStore } from "../StickersPicker/useGalleryStore";
import type { GalleryItemPhoto } from "../../api/types";
import { useSafeAreaBottom } from "../../utils/safeArea";
import WebApp from "@twa-dev/sdk";
import { getRandomArrayElement } from "../../utils/getRandomArrayElement";

interface StickerPackProps {
	stickers: GalleryItemPhoto[];
}

const EMOJI_REGEXP = new RegExp(
	"^(?:[\\u2700-\\u27BF]|[\\uE0020-\\uE007F]|[\\u1F000-\\u1FAFF]|[\\u1F300-\\u1F5FF]|[\\u1F600-\\u1F6FF]|[\\u1F700-\\u1F77F]|[\\uFF00-\\uFFFF])*$",
);

function getRandomEmoji() {
	// Define several known Unicode ranges that contain emoji
	const emojis = [
		"😂", // 1. Laughing with tears
		"🤣", // 2. Rolling on the floor laughing
		"❤️", // 3. Red heart
		"🙏", // 4. Praying hands
		"😭", // 5. Loudly crying face
		"😍", // 6. Smiling face with heart-eyes
		"✨", // 7. Sparkles
		"🔥", // 8. Fire
		"😊", // 9. Slightly smiling face
		"🥰", // 10. Blushing smiling face
	];

	return getRandomArrayElement(emojis);
}

const stickerStyle = {
	width: "100px",
	height: "150px",
	borderRadius: "10px",
	overflow: "hidden",
	objectFit: "cover",
	cursor: "pointer",
};

export const StickerPack = ({ stickers }: StickerPackProps) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [title, setTitle] = useState(t("stickerPack.defaultName"));
	const { setTabbarVisible } = useStore();

	const [focusedInputs, setFocusedInputs] = useState<number[]>([]);

	const { exportStickers, setSelectedItems } = useGalleryStore();

	const safeAreaBottom = useSafeAreaBottom();

	const [selectedStickers, setSelectedStickers] =
		useState<GalleryItemPhoto[]>(stickers);

	const [emojis, setEmojis] = useState<string[]>(
		Array.from({ length: stickers.length }, () => getRandomEmoji()),
	);

	const [isScrollEnabled, setIsScrollEnabled] = useState(true);

	const swapy = useRef<Swapy | null>(null);
	const container = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setTabbarVisible(false);

		return () => {
			setTabbarVisible(true);
		};
	}, [setTabbarVisible]);

	useEffect(() => {
		document.getElementById("sticker-pack-name")?.focus();
	}, []);

	useEffect(() => {
		// If container element is loaded
		if (container.current) {
			swapy.current = createSwapy(container.current, {
				swapMode: "hover",
			});

			swapy.current.onSwapStart((event) => {
				setIsScrollEnabled(false);
			});

			swapy.current.onSwapEnd(() => {
				setIsScrollEnabled(true);
			});

			// Your event listeners
			swapy.current.onSwap((event) => {
				setSelectedStickers(
					Object.keys(event.newSlotItemMap.asObject).map((item) =>
						selectedStickers.find(
							(sticker) => sticker.photo_id === Number.parseInt(item),
						),
					),
				);
			});
		}

		return () => {
			// Destroy the swapy instance on component destroy
			swapy.current?.destroy();
		};
	}, [setSelectedStickers]);

	if (selectedStickers.length === 0) {
		return <div>{t("stickerPack.noStickers")}</div>;
	}

	const isValid = title.length > 0 && selectedStickers.length > 0;

	const handleExport = async () => {
		setIsLoading(true);
		try {
			const stickerURL = await exportStickers(
				selectedStickers.map((sticker) => ({
					photo_id: sticker.photo_id,
					emoji: emojis[selectedStickers.indexOf(sticker)],
				})),
				title,
			);

			WebApp.openTelegramLink(stickerURL);
			setSelectedItems([]);
		} catch (error) {
			console.error(error);
			WebApp.showPopup({
				title: t("stickerPack.error.title"),
				message: t("stickerPack.error.exportFailed"),
				buttons: [
					{ id: "my-id", type: "default", text: t("stickerPack.error.ok") },
				],
			});
		} finally {
			setIsLoading(false);
		}
	};

	const isFocused = focusedInputs.length > 0;

	return (
		<div
			className={clsx(
				"container",
				"!h-fit",
				!isScrollEnabled && "!overflow-hidden",
			)}
		>
			<div className="fixedInput">
				<Input
					id="sticker-pack-name"
					placeholder={t("stickerPack.namePlaceholder")}
					value={title}
					autoFocus
					onChange={(e) => setTitle(e.target.value)}
				/>
			</div>

			<div className="pt-[16px] flex items-center justify-between">
				<div>
					<Headline
						weight="2"
						className="flex items-center justify-between gap-[2px]"
					>
						{t("stickerPack.setEmojis")}
					</Headline>
				</div>
				<div className="text-hint text-[19px] font-bold">
					{selectedStickers.length}
				</div>
			</div>

			<div
				className="grid grid-cols-3 grid-rows-1 justify-items-center gap-4 mt-[8px]"
				style={{
					marginBottom: 62 + safeAreaBottom,
				}}
				ref={container}
			>
				{selectedStickers.map((sticker, index) => {
					return (
						<div
							key={sticker.photo_id}
							className="w-[100px] h-fit"
							data-swapy-slot={sticker.photo_id}
						>
							<div
								className="relative w-[100px] h-fit cursor-pointer item"
								data-swapy-item={sticker.photo_id}
							>
								<div className="w-[100px] h-fit rounded-[10px] overflow-hidden">
									<div className="w-[100px] h-[150px] rounded-[10px] cursor-pointer">
										<CachedImage
											className="image-container"
											key={`${sticker.photo_id}-${index}`}
											src={sticker.url}
											alt={`Image ${sticker.photo_id}`}
											style={stickerStyle}
											threshold={100}
										/>
									</div>

									<input
										type="emoji"
										placeholder={t("stickerPack.emojiPlaceholder")}
										value={emojis[index]}
										onFocus={() => setFocusedInputs((prev) => [...prev, index])}
										onBlur={() =>
											setFocusedInputs((prev) =>
												prev.filter((i) => i !== index),
											)
										}
										onChange={(e) => {
											const newValue = e.target.value.trim();

											if (!EMOJI_REGEXP.test(newValue)) {
												e.preventDefault();
												return;
											}

											setEmojis((prev) => {
												const newEmojis = [...prev];
												newEmojis[index] = newValue;
												return newEmojis;
											});
										}}
										className={clsx(
											"w-full h-[32px] mt-[4px] rounded-[12px] overflow-hidden",
											"bg-secondary-bg-color",
											"text-text text-center",
										)}
									/>
								</div>
							</div>
						</div>
					);
				})}
			</div>

			{isFocused && <div className="h-[100px]" />}

			{!isFocused && (
				<FooterButton onClick={handleExport} disabled={!isValid || isLoading}>
					<div className="flex items-center justify-center">
						<span className="text-[15px] font-medium text-white">
							{isLoading ? (
								<Spinner size="s" />
							) : (
								t("stickerPack.exportToTelegram")
							)}
						</span>
					</div>
				</FooterButton>
			)}
		</div>
	);
};

export default StickerPack;
