import { Button, Title } from "@telegram-apps/telegram-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { InferenceModel } from "../../api/types";
import CachedImage from "../../components/CachedImage/CachedImage";
import { useNavigation } from "../../hooks/useLocation";
import IconBolt from "../../icons/IconBolt";
import { hapticClick } from "../../utils/haptic";
import clsx from "clsx";
import { isCurrentUserTestUser } from "../../utils/test";
import { useDiscoverStore } from "./useDiscoverStore";

interface DiscoverItemProps {
	style: InferenceModel;
	isSticker?: boolean;
}

interface Styles {
	[key: string]: React.CSSProperties;
}

const styles: Styles = {
	card: {
		flexShrink: 0,
		width: "100%",
		minHeight: "240px",
		borderRadius: "16px",
		boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1)",
		position: "relative",
		marginBottom: 8,
	},
	imageGrid: {
		boxSizing: "border-box",

		display: "grid",
		gridTemplateColumns: "repeat(3, 1fr)",
		gridTemplateRows: "150px",
		height: "calc(150px + 20px + 14px)",
		gap: "8px",

		padding: "20px 20px 10px",
	},
	image: {
		width: "100%",
		height: "150px",
		borderRadius: "10px",
		objectFit: "cover",
	},
	imageSticker: {
		width: "100%",
		height: "150px",
		objectFit: "contain",
	},
	buttonContainer: {
		padding: "0 20px 20px",
	},
	button: {
		backgroundColor: "white",
		color: "#8B5CF6",
		border: "none",
		padding: "12px 24px",
		borderRadius: "8px",
		fontWeight: "600",
		cursor: "pointer",
		transition: "background-color 0.3s ease",
	},
};

const DiscoverItem: React.FC<DiscoverItemProps> = (props) => {
	const navigation = useNavigation();
	const { setChooseStyleOpen } = useDiscoverStore();
	const { t } = useTranslation();
	const images = [
		props.style.example_1,
		props.style.example_2,
		props.style.example_3,
	];

	const handleItemClick = () => {
		hapticClick("light");

		if (props.style.category_name === "Dota 2") {
			setChooseStyleOpen({
				style_id: props.style.id,
				two_photo: false,
			});
		} else {
			navigation.push("/discover/instruction", { styleId: props.style.id });
		}
	};

	return (
		// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div
			style={{
				...styles.card,
				background: `linear-gradient(180deg, #${props.style.gradient_from}, #${props.style.gradient_to})`,
			}}
			className={clsx(
				"cursor-pointer shadow-modelCard transition-all duration-200",
				"shadow-modelCard",
			)}
			onClick={handleItemClick}
		>
			<div style={styles.imageGrid}>
				{images?.map((src: string, index: number) => (
					<CachedImage
						key={`image-${index}`}
						src={src}
						alt={t("discover.cardImage", { number: index + 1 })}
						style={props.isSticker ? styles.imageSticker : styles.image}
					/>
				))}
			</div>
			<div style={styles.buttonContainer}>
				<div className="flex flex-row justify-between items-center">
					<Title level="2" weight="1" className="text-white">
						{props.style.name}
						{isCurrentUserTestUser() && ` (${props.style.id})`}
					</Title>
					<Button size={"s"} style={{ minWidth: 53, width: 53 }}>
						<div className="flex items-center gap-[4px]">
							{props.style.price}
							<IconBolt width={13.65} height={20} />
						</div>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default DiscoverItem;
