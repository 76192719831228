import * as Sentry from "@sentry/react";

import { createRoot } from "react-dom/client";
import App from "./App";

import "./index.css";
import "@telegram-apps/telegram-ui/dist/styles.css";
import WebApp from "@twa-dev/sdk";
import "./i18n";

// Only initialize Sentry in production
if (import.meta.env.PROD) {
	Sentry.init({
		dsn: "https://d93877f5e220c9e86207b98946d97e24@o257119.ingest.us.sentry.io/4508763424817152",
		integrations: [],
		// Adjust this value to control how many errors to send
		tracesSampleRate: 0.5,
		// Only send errors in production
		enabled: import.meta.env.PROD,
	});
}

WebApp.setHeaderColor("bg_color");

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(<App />);
