import React from "react";

export const IconEllipses20: React.FC<React.HTMLProps<SVGSVGElement>> = (
	props,
) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M16 12C14.8954 12 14 11.1046 14 10C14 8.89543 14.8954 8 16 8C17.1046 8 18 8.89543 18 10C18 11.1046 17.1046 12 16 12Z"
				fill="currentColor"
			/>
			<path
				d="M10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12Z"
				fill="currentColor"
			/>
			<path
				d="M4 12C2.89543 12 2 11.1046 2 10C2 8.89543 2.89543 8 4 8C5.10457 8 6 8.89543 6 10C6 11.1046 5.10457 12 4 12Z"
				fill="currentColor"
			/>
		</svg>
	);
};
