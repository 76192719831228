import type { FC } from "react";

export const IconShare: FC<React.HTMLProps<SVGSVGElement>> = (props) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
		<svg
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.637 15.6091V13.7207C11.637 12.7818 10.8759 12.0207 9.93698 12.0207H9.75483C8.66226 12.0207 7.64141 12.144 6.71745 12.4232C5.77412 12.7027 4.92356 13.156 4.19026 13.791C3.84124 14.0906 3.52268 14.4269 3.23237 14.7951C3.29145 13.8713 3.43024 13.0477 3.63936 12.3175C3.92095 11.3343 4.32935 10.5528 4.83878 9.93538M5.30037 15.0785C4.75943 15.5422 4.28749 16.1494 3.88454 16.9001C3.71343 17.2257 3.52024 17.4438 3.30497 17.5542C3.09522 17.6646 2.86891 17.7198 2.62603 17.7198C2.29485 17.7198 2.02438 17.5624 1.81463 17.2478C1.60488 16.9332 1.5 16.464 1.5 15.8403C1.5 14.3554 1.66835 13.0252 2.00506 11.8495C2.34177 10.6738 2.84959 9.67468 3.52852 8.85223C4.21297 8.02426 5.07406 7.39501 6.11178 6.96446C7.14949 6.5284 8.36384 6.31037 9.75483 6.31037H9.93698V3.45389C9.93698 3.11166 10.0529 2.82187 10.2847 2.58452C10.5166 2.34165 10.8091 2.22021 11.1624 2.22021C11.3942 2.22021 11.6067 2.26989 11.7999 2.36925C11.9931 2.4686 12.2139 2.6342 12.4623 2.86603L19.0363 8.97642C19.2019 9.13098 19.3206 9.29933 19.3924 9.48148C19.4641 9.65812 19.5 9.82923 19.5 9.99482C19.5 10.1549 19.4641 10.326 19.3924 10.5082C19.3206 10.6903 19.2019 10.8587 19.0363 11.0132L12.4623 17.165C12.236 17.3803 12.0207 17.5348 11.8165 17.6287C11.6122 17.728 11.3914 17.7777 11.1541 17.7777C10.8063 17.7777 10.5166 17.6618 10.2847 17.43C10.0529 17.2037 9.93698 16.9166 9.93698 16.5689V13.7207H9.75483C9.2108 13.7207 8.70485 13.7553 8.23698 13.8245C7.86938 13.8789 7.52528 13.9547 7.20469 14.0519C6.47608 14.2671 5.84131 14.6094 5.30037 15.0785ZM11.637 15.6091L17.6358 9.99558L11.637 4.41987V6.31037C11.637 7.24926 10.8759 8.01037 9.93698 8.01037H9.75483C8.53829 8.01037 7.55665 8.2013 6.77035 8.53172L6.76326 8.5347C5.96957 8.86399 5.3384 9.33111 4.83878 9.93538"
				fill="currentColor"
			/>
		</svg>
	);
};
