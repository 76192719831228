import { create } from "zustand";
import { exportStickers, getGallery } from "../../api/api";
import type {
	ExportSticker,
	GalleryItem,
	GalleryItemPhoto,
} from "../../api/types";

type FetchError = {
	message: string;
	code?: string;
	status?: number;
};

interface GalleryState {
	items: GalleryItem[];
	rawItems: GalleryItem[];
	selectedItems: GalleryItemPhoto[];
	isInitialLoading: boolean;
	isRefetching: boolean;
	isNewResults: boolean;
	error: FetchError | null;

	fetchGallery: () => Promise<void>;
	exportStickers: (stickers: ExportSticker[], title: string) => Promise<string>;
	setSelectedItems: (items: GalleryItemPhoto[]) => void;
	setIsNewResults: (isNewResults: boolean) => void;
}

export const useGalleryStore = create<GalleryState>((set, get) => ({
	items: [],
	rawItems: [],
	selectedItems: [],
	isInitialLoading: true,
	isRefetching: false,
	isNewResults: false,
	error: null,

	setSelectedItems: (items: GalleryItemPhoto[]) => {
		console.log("setSelectedItems", items);
		set((state) => ({
			...state,
			selectedItems: items,
		}));
	},

	fetchGallery: async () => {
		const hasExistingData = get().items.length > 0;

		set((state) => ({
			...state,
			isInitialLoading: !hasExistingData,
			isRefetching: hasExistingData,
			error: null,
		}));

		try {
			const response = await getGallery();

			const filteredItems = response.data.items.filter((item) =>
				["style", "category"].includes(item.details.requestType),
			);

			set((state) => ({
				...state,
				items: filteredItems.filter((item) => item.status === "done"),
				rawItems: filteredItems,
				isInitialLoading: false,
				isRefetching: false,
			}));
		} catch (err) {
			const error: FetchError = {
				message: err instanceof Error ? err.message : "Failed to fetch gallery",
				status: err instanceof Response ? err.status : undefined,
			};
			set((state) => ({
				...state,
				error,
				isInitialLoading: false,
				isRefetching: false,
			}));
		}
	},
	exportStickers: async (
		stickers: ExportSticker[],
		title: string,
	): Promise<string> => {
		return exportStickers(stickers, title);
	},

	setIsNewResults: (isNewResults: boolean) => {
		set((state) => ({
			...state,
			isNewResults,
		}));
	},
}));
