import {
	Button,
	IconButton,
	SegmentedControl,
	Spinner,
	Text,
} from "@telegram-apps/telegram-ui";
import type { FC, ChangeEvent } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import IconPhotoUpload from "../../icons/IconPhotoUpload";
import PickerSexSelector from "./PickerSexSelector";
import "./Picker.css";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { generateCategoryStickers, postTasks, uploadFile } from "../../api/api";
import type { MediaUploadResponse } from "../../api/types";
import { formatter } from "../../helpers/formatter";
import { useNavigation } from "../../hooks/useLocation";
import useStore from "../../hooks/useStore";
import IconBolt from "../../icons/IconBolt";
import { TabType } from "../../types/tabs";
import { convertPngToJpeg } from "../../utils/png";
import { useDiscoverStore } from "../Discover/useDiscoverStore";
import { hapticClick } from "../../utils/haptic";
import TopUp from "../TopUp/TopUp";
import { ClosableModal } from "../../components/ClosableModal/ClosableModal";
import { useGalleryStore } from "../StickersPicker/useGalleryStore";

import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import WebApp from "@twa-dev/sdk";
import clsx from "clsx";

interface PickerProps {
	styleId?: number;
	category?: number;
	two_photo?: boolean;
	style?: "clean" | "funny";
}

interface FaceUploadState {
	sex: "male" | "female";
	file: File | null;
	uploadResponse: MediaUploadResponse | null;
	isUploading: boolean;
}

const Picker: FC<PickerProps> = (props) => {
	const objectUrlMap = useRef(new Map<string, string>());

	const { t } = useTranslation();
	const { setTabbarVisible } = useStore();
	const navigation = useNavigation();
	const { setActiveTab } = useStore();
	const { models: discoverModels, categories } = useDiscoverStore();
	const isTwoPhoto =
		props.two_photo ??
		discoverModels.some(
			(model) => model.id === props.styleId && model.two_photo,
		);

	const isActive = isTwoPhoto;

	const { fetchGallery } = useGalleryStore();

	const {
		isTopUpModalOpen,
		closeTopUpModal,
		handleTopUpComplete,
		profile,
		models,
		openTopUpModal,
		fetchInitialData,
	} = useDiscoverStore();

	const currentModel = models.find((model) => model.id === props.styleId);

	const currentCategory = categories.meme.find(
		(category) => category.id === props.category,
	);

	const modelType = currentModel?.type ?? "photo";

	const [mode, setMode] = useState<"single" | "double">("single");

	const fileInputRef1 = useRef<HTMLInputElement | null>(null);
	const fileInputRef2 = useRef<HTMLInputElement | null>(null);

	const fileAbortController1 = useRef<AbortController | null>(null);
	const fileAbortController2 = useRef<AbortController | null>(null);

	const [face1, setFace1] = useState<FaceUploadState>({
		sex: "male",
		file: null,
		uploadResponse: null,
		isUploading: false,
	});

	const [face2, setFace2] = useState<FaceUploadState>({
		sex: "female",
		file: null,
		uploadResponse: null,
		isUploading: false,
	});

	useEffect(() => {
		setTabbarVisible(false);

		return () => {
			setTabbarVisible(true);
		};
	}, [setTabbarVisible]);

	const handleUploadClick = (faceNum: 1 | 2) => {
		const face = faceNum === 1 ? face1 : face2;
		if (face.isUploading || face.uploadResponse) return;

		const fileInput = faceNum === 1 ? fileInputRef1 : fileInputRef2;
		if (fileInput.current) {
			fileInput.current.click();
		}
	};

	const handleFileChange = async (
		event: ChangeEvent<HTMLInputElement>,
		faceNum: 1 | 2,
	) => {
		if (event.target?.files?.[0]) {
			const file = event.target.files[0];

			if (!file) {
				alert("Please select a JPEG image.");
				return;
			}

			const setFace = faceNum === 1 ? setFace1 : setFace2;
			const face = faceNum === 1 ? face1 : face2;

			if (file.type === "image/jpeg") {
				setFace({ ...face, isUploading: true });

				const newAbortController = new AbortController();
				(faceNum === 1 ? fileAbortController1 : fileAbortController2).current =
					newAbortController;

				uploadFile(file, newAbortController.signal)
					.then((response) => {
						if (newAbortController.signal.aborted) {
							return;
						}

						setFace({
							...face,
							uploadResponse: response.data,
							isUploading: false,
							file: file,
						});
					})
					.catch(() => {
						if (newAbortController.signal.aborted) {
							return;
						}
						setFace({ ...face, isUploading: false });
					});
			}

			if (file.type === "image/png") {
				setFace({ ...face, isUploading: true });
				convertPngToJpeg(file)
					.then((jpegFile) => {
						uploadFile(
							jpegFile,
							(faceNum === 1 ? fileAbortController1 : fileAbortController2)
								.current?.signal,
						)
							.then((response) => {
								setFace({
									...face,
									uploadResponse: response.data,
									isUploading: false,
									file: jpegFile,
								});
							})
							.catch(() => {
								setFace({ ...face, isUploading: false });
							});
					})
					.finally(() => {
						setFace({ ...face, isUploading: false });
					});
			}
		}
	};

	const handleStartClick = async () => {
		hapticClick("light");

		const isOverlay = props.style === "funny";
		const styleId = props.styleId ?? 0;

		if (props.category && face1.uploadResponse) {
			if (mode === "single") {
				await generateCategoryStickers(
					face1.uploadResponse?.photo_id,
					props.category,
					face1.sex,
					isOverlay,
				);
			} else {
				if (face1.uploadResponse && face2.uploadResponse) {
					await generateCategoryStickers(
						[face1.uploadResponse?.photo_id, face2.uploadResponse?.photo_id],
						props.category,
						[face1.sex, face2.sex],
						isOverlay,
					);
				}
			}
		} else {
			if (mode === "single") {
				if (face1.uploadResponse) {
					await postTasks(
						styleId,
						face1.uploadResponse.photo_id,
						face1.sex,
						isOverlay,
					);
				}
			} else {
				if (face1.uploadResponse && face2.uploadResponse) {
					await postTasks(
						styleId,
						[face1.uploadResponse.photo_id, face2.uploadResponse.photo_id],
						[face1.sex, face2.sex],
						isOverlay,
					);
				}
			}
		}

		fetchGallery();
		fetchInitialData();

		navigation.pop();
		navigation.pop();

		setTimeout(() => {
			setActiveTab(TabType.PROFILE);
		}, 0);
	};

	const handleUploadCancel = (faceNum: 1 | 2) => {
		const controller =
			faceNum === 1 ? fileAbortController1 : fileAbortController2;

		controller.current?.abort();
		const setFace = faceNum === 1 ? setFace1 : setFace2;
		const face = faceNum === 1 ? face1 : face2;
		setFace({ ...face, file: null, uploadResponse: null, isUploading: false });
	};

	const handleFileCancel = (faceNum: 1 | 2) => {
		console.log("handleFileCancel", faceNum);

		const setFace = faceNum === 1 ? setFace1 : setFace2;
		const face = faceNum === 1 ? face1 : face2;
		const fileInput = faceNum === 1 ? fileInputRef1 : fileInputRef2;

		setFace({ ...face, uploadResponse: null, file: null });
		if (fileInput.current) {
			fileInput.current.value = "";
		}
	};

	const handleTopUpClick = () => {
		hapticClick("light");
		openTopUpModal();
	};

	const renderUploadButton = (faceNum: 1 | 2) => {
		const face = faceNum === 1 ? face1 : face2;

		let objectURL = null;

		if (face.file) {
			const fileId = face.file.name;

			objectURL = objectUrlMap.current.get(fileId);

			if (!objectURL) {
				objectURL = URL.createObjectURL(face.file);
				objectUrlMap.current.set(fileId, objectURL);
			}
		}

		return (
			<div
				className={`upload-button ${face.file ? "upload-button_has-file" : "upload-button_no-file"}`}
				style={{
					background: face.file
						? `url(${objectURL}) center/cover no-repeat`
						: "none",
					border: face.uploadResponse
						? "1px solid rgba(0, 0, 0, 0.10)"
						: undefined,
					cursor:
						!face.isUploading && !face.uploadResponse ? "pointer" : undefined,
					height: mode === "double" ? "180px" : "",
				}}
				onClick={() => handleUploadClick(faceNum)}
				onKeyDown={(e) => {
					if (e.key === "Enter" || e.key === " ") {
						handleUploadClick(faceNum);
					}
				}}
				disabled={face.isUploading || face.uploadResponse !== null}
			>
				{!face.isUploading && !face.uploadResponse && (
					<>
						<IconPhotoUpload color="var(--tgui--button_color)" />
						<Text
							weight="2"
							style={{
								textAlign: "center",
								color: "var(--tgui--button_color)",
							}}
						>
							{t(`picker.uploadPhoto.${face.sex}`)}
						</Text>
					</>
				)}

				{face.isUploading && (
					<>
						<Spinner size="m" />
						<Text weight="3" style={{ textAlign: "center" }}>
							{t("picker.uploading")}
						</Text>
						<Button
							onClick={() => handleUploadCancel(faceNum)}
							size="s"
							mode="bezeled"
							style={{
								position: "absolute",
								bottom: "20px",
								left: "50%",
								transform: "translateX(-50%)",
							}}
						>
							{t("picker.cancel")}
						</Button>
					</>
				)}

				{face.file && (
					<IconButton
						className="upload-button__cancel-button"
						onClick={() => handleFileCancel(faceNum)}
						size="s"
						mode="bezeled"
					>
						<Icon28Close />
					</IconButton>
				)}
			</div>
		);
	};

	const price = currentCategory?.price ?? currentModel?.price ?? 5;
	const delta = price - (profile?.balance ?? 0);
	const isEnoughBalance = (profile?.balance ?? 0) >= price;
	const isUploaded =
		mode === "single"
			? face1.uploadResponse
			: face1.uploadResponse && face2.uploadResponse;

	const styleName = currentModel?.name ?? currentCategory?.name;
	const generationCount = currentCategory
		? models.filter((model) => model.category_name === currentCategory.name)
				.length * 2
		: modelType === "photo"
			? 10
			: 6;

	const renderContent = () => {
		return (
			<>
				<div
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
						gap: "16px",
					}}
				>
					<div style={{ flex: 1 }} />
					{isActive && (
						<SegmentedControl className="h-[32px] !bg-secondary-bg-color">
							<SegmentedControlItem
								onClick={() => setMode("single")}
								selected={mode === "single"}
								className={clsx(mode === "single" && "!bg-background")}
							>
								{t("picker.modeSelector.single")}
							</SegmentedControlItem>
							<SegmentedControlItem
								onClick={() => setMode("double")}
								selected={mode === "double"}
								className={clsx(mode === "double" && "!bg-background")}
							>
								{t("picker.modeSelector.double")}
							</SegmentedControlItem>
						</SegmentedControl>
					)}

					<div
						style={{ display: "flex", flexDirection: "column", gap: "16px" }}
						className={mode === "single" ? "hidden" : ""}
					>
						<PickerSexSelector
							onSelect={(sex) => setFace1((prev) => ({ ...prev, sex }))}
							selected={face1.sex}
						/>
						<input
							type="file"
							accept="image/jpeg,image/png"
							ref={fileInputRef1}
							onChange={(e) => handleFileChange(e, 1)}
							style={{
								display: "none",
								height: mode === "double" ? "180px" : "",
							}}
						/>
						{renderUploadButton(1)}
					</div>

					{mode === "double" && (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "16px",
								marginTop: "16px",
							}}
						>
							<PickerSexSelector
								onSelect={(sex) => setFace2((prev) => ({ ...prev, sex }))}
								selected={face2.sex}
							/>
							<input
								type="file"
								accept="image/jpeg,image/png"
								ref={fileInputRef2}
								onChange={(e) => handleFileChange(e, 2)}
								style={{
									display: "none",
									height: mode === "double" ? "180px" : "",
								}}
							/>
							{renderUploadButton(2)}
						</div>
					)}

					<div style={{ flex: 1 }} />
					<div
						style={{ display: "flex", flexDirection: "column", gap: "12px" }}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								gap: "2px",
							}}
						>
							<Text
								weight="3"
								style={{
									color: "var(--tgui--hint_color)",
									fontSize: "13px",
									textAlign: "center",
									lineHeight: "16px",
								}}
							>
								{t("picker.balance")} {formatter.format(profile?.balance || 0)}
							</Text>
							<IconBolt width={8.5} height={12} />
						</div>
						<div className="action-buttons">
							{isEnoughBalance ? (
								<Button
									size="s"
									mode="filled"
									onClick={handleStartClick}
									disabled={!isUploaded}
									style={{
										width: "100%",
										borderRadius: "20px",
										flexBasis: "0",
										flexGrow: "1",
									}}
									after={<IconBolt width={14} height={20} />}
								>
									{t("picker.generate")} • {price}
								</Button>
							) : (
								<Button
									size="s"
									mode="filled"
									onClick={handleTopUpClick}
									style={{
										width: "100%",
										borderRadius: "20px",
										flexBasis: "0",
										flexGrow: "1",
									}}
									after={<IconBolt width={14} height={20} />}
								>
									{t("picker.topUp")} • {formatter.format(delta)}
								</Button>
							)}
						</div>
						<Text
							weight="3"
							style={{
								color: "var(--tgui--hint_color)",
								fontSize: "13px",
								textAlign: "center",
								lineHeight: "16px",
								paddingBottom: "16px",
							}}
						>
							{t("picker.resultCount", {
								style: styleName,
								count: generationCount,
							})}
						</Text>
					</div>
				</div>
			</>
		);
	};

	return (
		<div
			className="container"
			style={{ maxHeight: "calc(100vh - var(--safe-area-inset-bottom))" }}
		>
			<ClosableModal
				isOpen={isTopUpModalOpen}
				onOpenChange={closeTopUpModal}
				bgSecondary={true}
			>
				<TopUp
					balance={profile?.balance ?? 0}
					onClose={(e) => {
						hapticClick("light");
						handleTopUpComplete(e);
					}}
				/>
			</ClosableModal>
			{renderContent()}
		</div>
	);
};

export default Picker;
