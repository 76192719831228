import { create } from "zustand";
import type TabStore from "../store/TabStore";
import { TabType } from "../types/tabs";

const useStore = create<TabStore>((set) => ({
	activeTab: TabType.STICKERS,
	navigationStacks: {
		[TabType.DISCOVER]: [],
		[TabType.STICKERS]: [],
		[TabType.PROFILE]: [],
	},
	locations: {
		[TabType.DISCOVER]: [],
		[TabType.STICKERS]: [],
		[TabType.PROFILE]: [],
	},
	isTabbarVisible: true,
	setActiveTab: (tab) => set({ activeTab: tab }),
	pushLocation: (tab, screen) =>
		set((state) => ({
			navigationStacks: {
				...state.navigationStacks,
				[tab]: [...state.navigationStacks[tab], screen],
			},
			locations: {
				...state.locations,
				[tab]: [...state.locations[tab], screen.location],
			},
		})),
	popLocation: (tab) =>
		set((state) => {
			const currentStack = state.navigationStacks[tab];
			const currentLocations = state.locations[tab];

			if (currentStack.length > 1) {
				return {
					navigationStacks: {
						...state.navigationStacks,
						[tab]: currentStack.slice(0, -1),
					},
					locations: {
						...state.locations,
						[tab]: currentLocations.slice(0, -1),
					},
				};
			}

			return {
				navigationStacks: {
					...state.navigationStacks,
					[tab]: [],
				},
				locations: {
					...state.locations,
					[tab]: [],
				},
			};
		}),
	replaceLocation: (tab, screen) =>
		set((state) => ({
			navigationStacks: {
				...state.navigationStacks,
				[tab]: [...state.navigationStacks[tab].slice(0, -1), screen],
			},
			locations: {
				...state.locations,
				[tab]: [...state.locations[tab].slice(0, -1), screen.location],
			},
		})),
	setTabbarVisible: (visible: boolean) => set({ isTabbarVisible: visible }),
}));

export default useStore;
