import wontwork1 from "../icons/wontwork1.webp";
import wontwork2 from "../icons/wontwork2.webp";
import wontwork3 from "../icons/wontwork3.webp";
import wontwork4 from "../icons/wontwork4.webp";
import wontwork5 from "../icons/wontwork5.webp";
import wontwork6 from "../icons/wontwork6.webp";

import workbest1 from "../icons/workbest1.webp";
import workbest2 from "../icons/workbest2.webp";
import workbest3 from "../icons/workbest3.webp";
import workbest4 from "../icons/workbest4.webp";
import workbest5 from "../icons/workbest5.webp";
import workbest6 from "../icons/workbest6.webp";

const examples = [
	wontwork1,
	wontwork2,
	wontwork3,
	wontwork4,
	wontwork5,
	wontwork6,
	workbest1,
	workbest2,
	workbest3,
	workbest4,
	workbest5,
	workbest6,
];

export const fetchExamples = async () => {
	return Promise.all(
		examples.map(async (example) => {
			await fetch(example);
		}),
	);
};
