import { Button, Title, Caption, Text } from "@telegram-apps/telegram-ui";
import { useDiscoverStore } from "../Discover/useDiscoverStore";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigation } from "../../hooks/useLocation";
import { hapticClick } from "../../utils/haptic";
import clsx from "clsx";

export const ChooseStyle = () => {
	const [style, setStyle] = useState<"clean" | "funny">("clean");
	const { setChooseStyleOpen, chooseStyleOpen, models } = useDiscoverStore();
	const { t } = useTranslation();
	const navigation = useNavigation();

	if (!chooseStyleOpen) {
		return null;
	}

	const { style_id, two_photo } = chooseStyleOpen;

	const model = models.find((model) => model.id === style_id);

	if (!model) {
		return null;
	}

	const blockClassName = clsx(
		"flex-[1]",
		"relative",
		"h-[200px]",
		"cursor-pointer",
	);

	const boxClassName = clsx(
		"relative",
		"rounded-[16px]",
		"overflow-hidden",
		"h-[142px]",
		"w-full",
		"mt-[37px]",
		"bg-background",
		"border-[3px]",
	);

	const imageClassName = clsx(
		"absolute",
		"top-0",
		"left-0",
		"right-0",
		"bottom-0",
		"object-contain",
		"w-[30vw] h-[30vw]",
		"mx-auto",
		"inset-0",
	);

	const contentClassName = clsx(
		"absolute",
		"bottom-[10px]",
		"w-full",
		"flex",
		"flex-col",
		"items-center",
		"justify-center",
	);

	const handleContinue = () => {
		hapticClick("light");

		setChooseStyleOpen(null);
		navigation.push("/discover/instruction", {
			styleId: style_id,
			two_photo,
			style,
		});
	};

	return (
		<div>
			<div
				style={{
					paddingTop: "30px",
					paddingBottom: "16px",
					paddingLeft: "16px",
					paddingRight: "16px",
					display: "flex",
					gap: "24px",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						gap: "8px",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Title level="2" weight="1">
						{t("chooseStyle.title")}
					</Title>
					<div className="flex !w-[calc(100vw-32px)] flex-row gap-[10px]">
						{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
						<div
							className={clsx(blockClassName)}
							onClick={() => setStyle("clean")}
						>
							<div
								className={clsx(
									boxClassName,
									style === "clean" ? "border-link" : "border-transparent",
								)}
							>
								<div className={contentClassName}>
									<Text weight="1" className="!text-[20px] !leading-[25px]">
										{t("chooseStyle.clean")}
									</Text>
									<Text weight="1" className="!text-[10px] !leading-[13px]">
										{t("chooseStyle.clean_description")}
									</Text>
								</div>
							</div>
							<img
								src={model.example_1}
								alt="clean"
								className={imageClassName}
							/>
						</div>
						{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
						<div
							className={clsx(blockClassName)}
							onClick={() => setStyle("funny")}
						>
							<div
								className={clsx(
									boxClassName,
									style === "funny" ? "border-link" : "border-transparent",
								)}
							>
								<div className={contentClassName}>
									<Text weight="1" className="!text-[20px] !leading-[25px]">
										{t("chooseStyle.funny")}
									</Text>
									<Text weight="1" className="!text-[10px] !leading-[13px]">
										{t("chooseStyle.funny_description")}
									</Text>
								</div>
							</div>
							<img
								src={model.example_2}
								alt="clean"
								className={imageClassName}
							/>
						</div>
					</div>

					<Button className="!w-full" onClick={handleContinue}>
						{t("chooseStyle.continue")}
					</Button>
				</div>
			</div>
		</div>
	);
};
