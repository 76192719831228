import {
	Button,
	Caption,
	IconButton,
	Text,
	Title,
} from "@telegram-apps/telegram-ui";
import type { FC } from "react";
import { useState } from "react";
import "./DiscoverHeader.css";

import { ClosableModal } from "../../components/ClosableModal/ClosableModal";
import { formatter } from "../../helpers/formatter";
import { IconAdd } from "../../icons/IconAdd";
import IconBolt from "../../icons/IconBolt";
import { InvitedFriendsModal } from "./InvitedFriendsModal";
import { useDiscoverStore } from "./useDiscoverStore";
import { hapticClick } from "../../utils/haptic";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { IconShare } from "../../icons/IconShare";
import { IconGift } from "../../icons/IconGift";
import { Gift } from "../Gift/Gift";
import { ChooseStyle } from "../ChooseStyle/ChooseStyle";

interface DiscoverHeaderProps {
	onTopUp?: () => void;
}

const DiscoverHeader: FC<DiscoverHeaderProps> = (props) => {
	const [isInviteOpen, setIsInviteOpen] = useState(false);

	const [isGiftOpen, setIsGiftOpen] = useState(false);

	const { profile, chooseStyleOpen, setChooseStyleOpen } = useDiscoverStore();

	const { t, i18n } = useTranslation();

	const onOpenGift = () => {
		hapticClick("light");

		setIsGiftOpen(true);
	};

	const closeGift = () => {
		hapticClick("light");
		setIsGiftOpen(false);
	};

	const openInvite = () => {
		hapticClick("light");
		setIsInviteOpen(true);
	};

	const buttonClassName = clsx(
		"!w-[72px]",
		"!h-[64px]",
		"!bg-[#FFBE0033]",
		"!p-[0]",
	);

	const isRussian = i18n.language === "ru";

	return (
		<div className="discover-header">
			<ClosableModal isOpen={isInviteOpen} onOpenChange={setIsInviteOpen}>
				<InvitedFriendsModal />
			</ClosableModal>

			<ClosableModal
				isOpen={isGiftOpen}
				onOpenChange={(open) => {
					if (!open) {
						setIsGiftOpen(false);
					}
				}}
				bgSecondary={true}
			>
				<Gift onClose={closeGift} />
			</ClosableModal>

			<ClosableModal
				isOpen={chooseStyleOpen !== null}
				onOpenChange={(open) => {
					if (!open) {
						setChooseStyleOpen(null);
					}
				}}
				bgSecondary={true}
			>
				<ChooseStyle />
			</ClosableModal>

			<Button className={buttonClassName} onClick={openInvite}>
				<div className="flex items-center justify-center flex-col text-text">
					<IconShare width={24} height={24} />
					<Text
						weight="3"
						className={clsx(
							"inline-flex items-center font-medium leading-[16px] tracking-[-0.08px]",
							isRussian ? "!text-[10px]" : "!text-[13px]",
						)}
					>
						{t("discoverHeader.invite")}
					</Text>
				</div>
			</Button>

			<div
				id="top-up"
				className={clsx(
					"box-border h-[64px]",
					"flex-1 rounded-[12px] p-[8px_12px]",
					"flex items-center gap-[8px] bg-secondary-fill cursor-pointer",
				)}
				onClick={props.onTopUp}
			>
				<div className="w-[100%] flex flex-row justify-between items-center">
					<div className="flex flex-col">
						<Caption
							weight="3"
							className="text-hint text-[13px] leading-[16px]"
						>
							{t("topUp.balance")}
						</Caption>
						<div className="flex gap-[4px] items-center">
							<IconBolt />
							<Title weight="1">
								{formatter.format(profile?.balance ?? 0)}
							</Title>
						</div>
					</div>
					<IconButton
						className="p-[7px] !text-[#1EC948] !bg-[#1ec948] rounded-[12px]"
						size="s"
					>
						<IconAdd width={18} height={18} />
					</IconButton>
				</div>
			</div>

			<Button className={buttonClassName} onClick={onOpenGift}>
				<div className="flex items-center justify-center flex-col text-text">
					<IconGift width={24} height={24} />
					<Text
						weight="3"
						className={clsx(
							"inline-flex items-center !text-[13px] font-medium leading-[16px] tracking-[-0.08px]",
							isRussian ? "!text-[10px]" : "!text-[13px]",
							"text-text",
						)}
					>
						{t("discoverHeader.gift")}
					</Text>
				</div>
			</Button>
		</div>
	);
};

export default DiscoverHeader;
