import type { FC } from "react";

export const IconSelectedChecked: FC<React.HTMLProps<SVGSVGElement>> = (
	props,
) => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<circle
				cx="14"
				cy="14"
				r="13"
				fill="#007AFF"
				stroke="white"
				strokeWidth="2"
			/>
			<path
				d="M8 14.5L12.5 19L19.5 10"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
