const IconSmile28: React.FC = () => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M25.2 15.1961C25.2 19.5724 21.1493 23.9961 15 23.9961C8.92487 23.9961 4 19.0712 4 12.9961C4 6.84755 8.8123 2.62247 12.9584 2.79523C13.9729 2.8375 15.2559 3.29858 16.6607 4.13409C18.0486 4.95956 19.4768 6.10365 20.7647 7.40315C22.053 8.70293 23.1774 10.1343 23.9735 11.5203C24.7797 12.9239 25.2 14.195 25.2 15.1961Z"
				strokeWidth="2"
				stroke="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.9978 13.7364C24.8479 14.2875 23.5597 14.5961 22.1994 14.5961C17.3393 14.5961 13.3994 10.6562 13.3994 5.7961C13.3994 4.4348 13.7085 3.1457 14.2604 1.99515C13.8232 1.88112 13.4008 1.81284 12.999 1.7961C12.7198 1.78446 12.4392 1.78966 12.1584 1.81106C11.6686 3.04406 11.3994 4.38864 11.3994 5.7961C11.3994 11.7608 16.2347 16.5961 22.1994 16.5961C23.6028 16.5961 24.9436 16.3284 26.1737 15.8414C26.1905 15.6269 26.199 15.4117 26.199 15.1961C26.199 14.7338 26.1289 14.2443 25.9978 13.7364Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default IconSmile28;
