import clsx from "clsx";
import { useDiscoverStore } from "./useDiscoverStore";
import { useTranslation } from "react-i18next";
import { TabType } from "../../types/tabs";
import { useNavigation } from "../../hooks/useLocation";
import { Button } from "@telegram-apps/telegram-ui";
import { hapticClick } from "../../utils/haptic";
import IconBolt from "../../icons/IconBolt";

export const DiscoverCategory = () => {
	const { t } = useTranslation();

	const { activeTab } = useNavigation();

	const isSticker = activeTab === TabType.STICKERS;

	const {
		models,
		categories,
		category: currentCategory,
		setCategory,
	} = useDiscoverStore();

	const filteredModels = models.filter((model) =>
		isSticker
			? ["meme", "img2img"].includes(model.type)
			: model.type === "photo",
	);

	const navigation = useNavigation();

	const currentCategories = isSticker ? categories.meme : categories.photo;
	const currentCategoryState = isSticker
		? currentCategory.meme
		: currentCategory.photo;

	const filteredCategories = currentCategories.filter((category) =>
		filteredModels.some((model) => model.category_name === category.name),
	);

	const categoriesItems = [
		{
			id: 0,
			name: t("modelCategories.all"),
		},
		...filteredCategories,
	];

	const cardClassName = clsx(
		"flex flex-col items-center justify-center",
		"py-[4px] px-[14px]",
		"text-hint",
		"font-medium text-[15px] leading-[20px]",
		"cursor-pointer",
		"whitespace-nowrap text-nowrap",
	);

	const selectedCardClassName =
		"text-link rounded-[10px] bg-[#00A7FF26] rounded-full";

	const onSelect = (id: number) => {
		const category = categoriesItems.find((category) => category.id === id);

		if (category) {
			setCategory(isSticker ? "meme" : "photo", category);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent, id: number) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			onSelect(id);
		}
	};

	const handleGenerateAll = () => {
		hapticClick("light");

		navigation.push("/discover/instruction", {
			category: currentCategoryState?.id,
			two_photo: currentCategoryState?.id === 9,
		});
	};

	const isAllCategory = currentCategoryState?.id === 0;

	return (
		<>
			{!isAllCategory && (
				<Button
					size="s"
					onClick={handleGenerateAll}
					style={{ height: "36px" }}
					className="w-full mb-[16px] flex-1"
				>
					<div className="flex items-center">
						{t("modelCategories.generateAll")}{" "}
						<IconBolt className="ml-[4px] w-[16px] h-[16px]" />
						<div className="mx-[4px]">{currentCategoryState?.price}</div>
						<div
							className={clsx(
								"flex-1 bg-white text-[#007AFF] text-[13px] leading-[18px]",
								"rounded-full overflow-hidden px-[3px] py-[2px]",
							)}
						>
							-{currentCategoryState?.discount}%
						</div>
					</div>
				</Button>
			)}
			<div
				className={clsx(
					"flex items-center",
					"w-full overflow-x-auto",
					"mb-[18px]",
					isAllCategory ? "mt-[14px]" : "mt-[0px]",
				)}
			>
				{categoriesItems.map((category) => (
					<button
						type="button"
						className={clsx(
							cardClassName,
							currentCategoryState?.id === category.id && selectedCardClassName,
						)}
						onClick={() => onSelect(category.id)}
						onKeyDown={(e) => handleKeyDown(e, category.id)}
						key={category.id}
					>
						{category.name}
					</button>
				))}
			</div>
		</>
	);
};
