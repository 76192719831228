/**
 * Convert a PNG file into a JPEG file (returns a Promise).
 *
 * @param {File} pngFile - The original PNG file.
 * @param {number} [quality=0.8] - JPEG quality from 0 (lowest) to 1 (highest).
 * @return {Promise<File>} A Promise resolving to the new JPEG File.
 */
export function convertPngToJpeg(pngFile: File, quality = 0.8): Promise<File> {
	return new Promise((resolve, reject) => {
		if (!(pngFile instanceof File)) {
			reject(new Error("Input is not a valid File object"));
			return;
		}
		if (!pngFile.type || !pngFile.type.includes("png")) {
			reject(new Error("File must be a PNG image"));
			return;
		}

		const reader = new FileReader();
		reader.onload = () => {
			const img = new Image();
			img.onload = () => {
				// Create an offscreen canvas
				const canvas = document.createElement("canvas");
				canvas.width = img.width;
				canvas.height = img.height;
				const ctx = canvas.getContext("2d");
				if (!ctx) {
					reject(new Error("Failed to get canvas context"));
					return;
				}

				// Draw the PNG image onto the canvas
				ctx.drawImage(img, 0, 0);

				// Convert the canvas to a JPEG data URL
				const jpegDataUrl = canvas.toDataURL("image/jpeg", quality);

				// Convert data URL back to a File
				const jpegFile = dataURLToFile(
					jpegDataUrl,
					pngFile.name.replace(/\.png$/, ".jpg") || "converted.jpg",
				);
				resolve(jpegFile);
			};
			img.onerror = (error) => {
				reject(error);
			};
			if (typeof reader.result !== "string") {
				reject(new Error("Failed to read file as string"));
				return;
			}
			img.src = reader.result;
		};
		reader.onerror = () => {
			reject(new Error("Failed to read the PNG file"));
		};
		reader.readAsDataURL(pngFile);
	});
}

/**
 * Helper function to convert a base64 data URL to a File.
 *
 * @param {string} dataURL - The data URL (e.g. "data:image/jpeg;base64,....")
 * @param {string} fileName - Name of the resulting file (e.g. "image.jpg").
 * @return {File} The resulting File object.
 */
export function dataURLToFile(dataURL: string, fileName: string) {
	const arr = dataURL.split(",");
	const mimeMatch = arr[0].match(/:(.*?);/);
	if (!mimeMatch) throw new Error("Invalid data URL");
	const mime = mimeMatch[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], fileName, { type: mime });
}

export async function convertImageToBase64(imageUrl: string): Promise<string> {
	// Fetch the image as a binary blob
	const response = await fetch("/check.png"); //imageUrl);

	const blob = await response.blob();

	// Convert blob to Base64 string using FileReader
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
		reader.readAsDataURL(blob);
	});
}
