import { Caption, Title } from "@telegram-apps/telegram-ui";
import { Animation } from "../../components/Animation";

const Stickers = () => {
	return (
		<div className="container">
			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "6px",
				}}
			>
				<div style={{ flex: 1 }}></div>
				<Animation
					animationId="duck"
					style={{
						width: "112px",
						height: "112px",
						minWidth: "112px",
						minHeight: "112px",
					}}
				/>
				<Title level="1" weight="2" style={{ textAlign: "center" }}>
					{"Stickers creation"}
					<br />
					{"is coming soon"}
				</Title>
				<Caption
					level="1"
					weight="3"
					style={{ color: "var(--tgui--hint_color)", textAlign: "center" }}
				>
					{"You will be able to create custom sticker packs with any"}
					<br />
					{"person via Stick. Stay tuned!"}
				</Caption>
				<div style={{ flex: 1 }}></div>
			</div>
		</div>
	);
};

export default Stickers;
