const IconChevron: React.FC = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="10" cy="10" r="10" fill="#31D158" />
			<path
				d="M5 10.5L8.5 14L14.5 7"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				stroke="white"
			/>
		</svg>
	);
};

export default IconChevron;
