import { Button } from "@telegram-apps/telegram-ui";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { useSafeAreaBottom } from "../../utils/safeArea";

interface FooterButtonProps {
	children: React.ReactNode;
	disabled?: boolean;
	onClick: () => void;
}

export const FOOTER_BUTTON_HEIGHT = 62;

export const FooterButton = ({
	children,
	onClick,
	disabled,
}: FooterButtonProps) => {
	return (
		typeof document !== "undefined" &&
		createPortal(
			<div
				className={clsx(
					"fixed bottom-[0] left-[0] z-[9999]",
					"w-screen bg-section-bg-color",
					"border-t-[0.33px] border-t-border",
				)}
				style={{
					paddingBottom: `max(var(--lvh,100vh) - var(--tg-viewport-height,100vh),0px)`,
				}}
			>
				<div className="flex items-center justify-center w-full h-full px-[16px] py-[16px]">
					<Button
						size="s"
						className="!bg-button w-full !h-[36px]"
						style={{
							borderRadius: "20px",
						}}
						onClick={onClick}
						disabled={disabled}
					>
						{children}
					</Button>
				</div>
			</div>,
			document.body,
		)
	);
};
