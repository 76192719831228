import { Caption, Text } from "@telegram-apps/telegram-ui";
import { ReactNode } from "react";

interface UploadInstructionItemProps {
	icon: ReactNode;
	title: string;
	subtitle: string;
	images: string[];
}

export const UploadInstructionItem: React.FC<UploadInstructionItemProps> = (
	props,
) => {
	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "2px",
					padding: "0 24px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "6px",
					}}
				>
					{props.icon}
					<Text weight="2">{props.title}</Text>
				</div>
				<Caption weight="3" style={{ color: "var(--tgui--hint_color)" }}>
					{props.subtitle}
				</Caption>
			</div>
			<div className="carousel-container px-[24px]">
				{props.images &&
					props.images.map((model: string, index: number) => (
						<div key={index} className="image-container">
							<img
								src={model}
								alt={`Example ${index}`}
								style={{
									width: "100px",
									height: "150px",
									borderRadius: "10px",
								}}
							/>
						</div>
					))}
			</div>
		</div>
	);
};
