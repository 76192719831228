import React from "react";

export const IconForward: React.FC<React.HTMLProps<SVGSVGElement>> = (
	props,
) => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.3409 4.2612C13.9572 4.6501 13.9613 5.27646 14.3502 5.66024L20.3161 11.5476H15.6387C13.3985 11.5476 11.6285 11.5476 10.2298 11.7139C8.79544 11.8845 7.62258 12.242 6.64658 13.0503C6.33183 13.311 6.04221 13.6006 5.78153 13.9154C4.9732 14.8914 4.61574 16.0642 4.44513 17.4986C4.27875 18.8973 4.27877 20.6673 4.27878 22.9075V22.9728C4.27878 23.5191 4.7217 23.962 5.26807 23.962C5.81444 23.962 6.25735 23.5191 6.25735 22.9728C6.25735 20.6529 6.259 19.0005 6.40985 17.7323C6.55808 16.4862 6.8383 15.7413 7.30534 15.1774C7.48714 14.9579 7.68911 14.7559 7.90862 14.5741C8.47254 14.1071 9.21737 13.8269 10.4635 13.6787C11.7317 13.5278 13.3841 13.5262 15.704 13.5262H20.3161L15.0486 18.7243C14.6597 19.1081 14.6555 19.7345 15.0393 20.1234C15.4231 20.5123 16.0494 20.5164 16.4383 20.1326L23.4219 13.241C23.6103 13.0551 23.7163 12.8015 23.7163 12.5369C23.7163 12.2722 23.6103 12.0186 23.4219 11.8327L15.74 4.25193C15.3511 3.86816 14.7247 3.87231 14.3409 4.2612Z"
				fill="currentColor"
			/>
		</svg>
	);
};
