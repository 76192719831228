import { create } from "zustand";
import {
	fetchCategories,
	fetchModels,
	fetchPrices,
	fetchProfile,
	sendGift,
} from "../../api/api";
import type { InferenceModel, Price, User } from "../../api/types";

type FetchError = {
	message: string;
	code?: string;
	status?: number;
};

type ModelCategory = {
	id: number;
	name: string;
	price: number;
	discount: number;
};

type CategoryState = {
	meme: ModelCategory | null;
	photo: ModelCategory | null;
};

type CategoriesState = {
	meme: ModelCategory[];
	photo: ModelCategory[];
};

type ChooseStyleState = {
	style_id: number;
	two_photo: boolean;
};

interface DiscoverState {
	chooseStyleOpen: ChooseStyleState | null;

	// Models state
	models: InferenceModel[];
	isModelsLoading: boolean;
	isModelsRefetching: boolean;
	modelsError: FetchError | null;

	// Profile state
	profile: User | null;
	isProfileLoading: boolean;
	isProfileRefetching: boolean;
	profileError: FetchError | null;

	// Prices state
	inviteBonus: number;
	prices: Price[] | null;
	isPricesLoading: boolean;
	isPricesRefetching: boolean;
	pricesError: FetchError | null;

	// Combined loading state
	isInitialDataReady: boolean;

	// Modal state
	isTopUpModalOpen: boolean;

	// Model categories
	category: CategoryState;
	categories: CategoriesState;

	// Actions
	fetchModels: () => Promise<void>;
	fetchProfile: () => Promise<void>;
	fetchPrices: () => Promise<void>;
	fetchInitialData: () => Promise<void>;
	fetchCategories: () => Promise<void>;
	openTopUpModal: () => void;
	closeTopUpModal: (isOpen: boolean) => void;
	handleTopUpComplete: (isSuccessPurchase: boolean) => void;
	setCategory: (type: "meme" | "photo", category: ModelCategory) => void;
	sendGift: (count: number) => Promise<string | undefined>;
	setChooseStyleOpen: (state: ChooseStyleState | null) => void;
}

const createFetchError = (err: unknown): FetchError => ({
	message: err instanceof Error ? err.message : "An error occurred",
	code: undefined,
	status: err instanceof Response ? err.status : undefined,
});

const discover = (
	set: (fn: (state: DiscoverState) => DiscoverState) => void,
	get: () => DiscoverState,
): DiscoverState => ({
	chooseStyleOpen: null,

	// Models state
	models: [],
	isModelsLoading: true,
	isModelsRefetching: false,
	modelsError: null,

	// Profile state
	profile: null,
	isProfileLoading: true,
	isProfileRefetching: false,
	profileError: null,

	// Prices state
	inviteBonus: 0,
	prices: null,
	isPricesLoading: true,
	isPricesRefetching: false,
	pricesError: null,

	// Combined loading state
	isInitialDataReady: false,

	// Modal state
	isTopUpModalOpen: false,

	category: {
		meme: { id: 0, name: "All" },
		photo: { id: 0, name: "All" },
	},
	categories: {
		meme: [],
		photo: [],
	},
	setCategory: (type: "meme" | "photo", category: ModelCategory) =>
		set((state: DiscoverState) => ({
			...state,
			category: {
				...state.category,
				[type]: category,
			},
		})),
	fetchModels: async () => {
		const hasExistingData = get().models.length > 0;

		set((state: DiscoverState) => ({
			...state,
			isModelsLoading: !hasExistingData,
			isModelsRefetching: hasExistingData,
			modelsError: null,
		}));

		try {
			const [responseMeme, responsePhoto] = await Promise.all([
				fetchModels("discover"),
				fetchModels("stickers"),
			]);

			const models = [
				...responseMeme.data.styles,
				...responsePhoto.data.styles,
			];

			console.log({ models });

			set((state: DiscoverState) => ({
				...state,
				models,
				isModelsLoading: false,
				isModelsRefetching: false,
				isInitialDataReady: !state.isProfileLoading && !state.profileError,
			}));
		} catch (err) {
			set((state: DiscoverState) => ({
				...state,
				modelsError: createFetchError(err),
				isModelsLoading: false,
				isModelsRefetching: false,
			}));
		}
	},

	fetchCategories: async () => {
		const hasExistingData =
			get().categories.meme.length > 0 && get().categories.photo.length > 0;

		set((state: DiscoverState) => ({
			...state,
			categoriesLoading: !hasExistingData,
			categoriesRefetching: hasExistingData,
			categoriesError: null,
		}));

		try {
			const response = await fetchCategories();
			set((state: DiscoverState) => ({
				...state,
				categories: {
					meme: response.data,
					photo: response.data,
				},
				categoriesLoading: false,
				categoriesRefetching: false,
			}));
		} catch (err) {
			set((state: DiscoverState) => ({
				...state,
				categoriesError: createFetchError(err),
			}));
		}
	},

	fetchProfile: async () => {
		const hasExistingData = get().profile !== null;

		set((state: DiscoverState) => ({
			...state,
			isProfileLoading: !hasExistingData,
			isProfileRefetching: hasExistingData,
			profileError: null,
		}));

		try {
			const response = await fetchProfile();
			set((state: DiscoverState) => ({
				...state,
				profile: response.data,
				isProfileLoading: false,
				isProfileRefetching: false,
				isInitialDataReady: !state.isModelsLoading && !state.modelsError,
			}));
		} catch (err) {
			set((state: DiscoverState) => ({
				...state,
				profileError: createFetchError(err),
				isProfileLoading: false,
				isProfileRefetching: false,
			}));
		}
	},
	fetchPrices: async () => {
		const hasExistingData = get().prices !== null;

		set((state: DiscoverState) => ({
			...state,
			isPricesLoading: !hasExistingData,
			isPricesRefetching: hasExistingData,
			pricesError: null,
		}));

		try {
			const response = await fetchPrices();
			set((state: DiscoverState) => ({
				...state,
				inviteBonus: response.data.invite_bonus,
				prices: response.data.items,
				isPricesLoading: false,
				isPricesRefetching: false,
			}));
		} catch (err) {
			if (hasExistingData) {
				console.error(err);
			}
			set((state: DiscoverState) => ({
				...state,
				pricesError: createFetchError(err),
				isPricesLoading: false,
				isPricesRefetching: false,
			}));
		}
	},

	fetchInitialData: async () => {
		await Promise.all([
			get().fetchModels(),
			get().fetchProfile(),
			get().fetchPrices(),
			get().fetchCategories(),
		]);
	},

	openTopUpModal: () => {
		set((state: DiscoverState) => ({ ...state, isTopUpModalOpen: true }));
	},

	closeTopUpModal: (isOpen: boolean) => {
		set((state: DiscoverState) => ({ ...state, isTopUpModalOpen: isOpen }));
	},

	handleTopUpComplete: (isSuccessPurchase: boolean) => {
		set((state: DiscoverState) => ({ ...state, isTopUpModalOpen: false }));
		if (isSuccessPurchase) {
			get().fetchInitialData();
		}
	},
	sendGift: async (count: number): Promise<string | undefined> => {
		const response = await sendGift(count);

		if (response.status >= 200 && response.status < 300) {
			get().fetchInitialData();

			return response.data.url;
		}

		return undefined;
	},

	setChooseStyleOpen: (styleState: ChooseStyleState | null) =>
		set((state: DiscoverState) => ({
			...state,
			chooseStyleOpen: styleState,
		})),
});

export const useDiscoverStore = create<DiscoverState>()(discover);
