import { create } from "zustand";

interface GalleryScrollState {
    scrollPositions: Record<number, number>;
    setScrollPosition: (galleryId: number, position: number) => void;
    getScrollPosition: (galleryId: number) => number;
}

export const useGalleryScrollStore = create<GalleryScrollState>((set, get) => ({
    scrollPositions: {},
    setScrollPosition: (galleryId: number, position: number) => {
        set((state) => ({
            scrollPositions: {
                ...state.scrollPositions,
                [galleryId]: position,
            },
        }));
    },
    getScrollPosition: (galleryId: number) => {
        return get().scrollPositions[galleryId] || 0;
    },
})); 