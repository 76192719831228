import React, { useEffect, useCallback, Suspense, Component } from "react";
import { useNavigation } from "../../hooks/useLocation";
import useStore from "../../hooks/useStore";
import type { TabType } from "../../types/tabs";
import WebApp from "@twa-dev/sdk";
import ReactGA from "react-ga4";

interface NavigationStackProps<T extends Record<string, unknown>> {
	tab: TabType;
	rootComponent: React.ComponentType<T>;
	rootProps?: T;
}

const NavigationStack = <T extends Record<string, unknown>>({
	tab,
	rootComponent: RootComponent,
	rootProps,
}: NavigationStackProps<T>) => {
	const { navigationStacks } = useStore();
	const navigation = useNavigation();
	const bb = WebApp.BackButton;
	const stack = navigationStacks[tab];
	const { activeTab } = useStore();

	const scrollPositions = React.useRef<Record<string, number>>({});

	const screens = [
		{
			id: "root",
			component: RootComponent,
			props: rootProps,
			location: { pathname: "/" },
		},
		...stack,
	];

	const handleBack = useCallback(() => {
		navigation.pop();
	}, [navigation]);

	const backButtonHandler = useCallback(() => {
		bb?.hide();
		handleBack();
	}, [bb, handleBack]);

	useEffect(() => {
		const hasBackStack = stack.length > 0;

		if (hasBackStack) {
			bb?.onClick(backButtonHandler);
			bb?.show();
		} else {
			bb?.hide();
		}

		return () => {
			bb?.offClick(backButtonHandler);
		};
	}, [stack, backButtonHandler, bb]);

	const containerRef = React.useRef<HTMLDivElement>(null);
	const screen = screens.at(-1);

	const pageID = `${activeTab}-${screen?.id}`;

	console.log("pageID", pageID);

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: pageID,
			title: pageID,
		});
	}, [pageID]);

	useEffect(() => {
		const currentContainer = document.getElementById(pageID);

		if (!currentContainer) return;

		const savedPosition = scrollPositions.current[pageID];

		if (savedPosition !== undefined) {
			currentContainer.scrollTop = savedPosition;
		} else {
			currentContainer.scrollTo({
				top: 0,
			});
		}

		const handleScroll = () => {
			scrollPositions.current[pageID] = currentContainer.scrollTop;
		};

		currentContainer.addEventListener("scroll", handleScroll);
		return () => {
			currentContainer.removeEventListener("scroll", handleScroll);
		};
	}, [pageID]);

	if (!screen) return null;

	const Component = screen.component;

	return (
		<div ref={containerRef}>
			<div
				key={screen.id}
				id={pageID}
				style={{
					backgroundColor: "var(--tg-theme-bg-color)",
					height: "fit-content",
					width: "100%",
					maxHeight: "var(--tg-viewport-height, 100vh)",
					overflowY: "scroll",
				}}
			>
				<div>
					<Component {...(screen.props || {})} />
				</div>
			</div>
		</div>
	);
};

export default NavigationStack;
