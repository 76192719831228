import type { CSSProperties } from "react";

import ticket from "./json/ticket.json";
import duck from "./json/duck.json";
import bolt from "./json/bolt.json";
import hands from "./json/hands.json";
import unicorn from "./json/unicorn.json";

import Lottie from "lottie-react";

interface AnimationProps {
	animationId: keyof typeof ANIMATIONS;
	style?: CSSProperties;
	loop?: boolean;
}

const ANIMATIONS = {
	ticket,
	duck,
	bolt,
	hands,
	unicorn,
};

export const Animation = ({
	animationId,
	style,
	loop = true,
}: AnimationProps) => {
	const animation = ANIMATIONS[animationId];

	if (!animation) {
		return null;
	}

	return (
		<div style={style}>
			<Lottie animationData={animation} loop={loop} style={style} />
		</div>
	);
};
