import { Subheadline } from "@telegram-apps/telegram-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./PickerSexSelector.css";

interface PickerSexSelectorProps {
	onSelect: (sex: "male" | "female") => void;
	selected: "male" | "female";
}

const PickerSexSelector: React.FC<PickerSexSelectorProps> = ({
	onSelect,
	selected,
}) => {
	const { t } = useTranslation();

	const handleSelect = (sex: "male" | "female") => {
		onSelect(sex);
	};

	const handleKeyPress =
		(sex: "male" | "female") => (event: React.KeyboardEvent) => {
			if (event.key === "Enter" || event.key === " ") {
				handleSelect(sex);
			}
		};

	return (
		<div className="hstack">
			<div className="spacer" />

			<div
				className="item"
				onClick={() => handleSelect("male")}
				onKeyDown={handleKeyPress("male")}
				role="button"
				tabIndex={0}
				style={{
					color:
						selected === "male"
							? "var(--tgui--button_color)"
							: "var(--tgui--secondary_hint_color)",
				}}
			>
				<Subheadline
					level="2"
					weight="3"
					style={{ textAlign: "center", fontWeight: "500" }}
				>
					🧔‍♂️
					<br />
					{t("picker.sexSelector.male")}
				</Subheadline>
				<div
					className={
						selected === "male" ? "indicator indicator-selected" : "indicator"
					}
				/>
			</div>

			<div className="spacer" />
			<div className="center-space" />
			<div className="spacer" />

			<div
				className="item"
				onClick={() => handleSelect("female")}
				onKeyDown={handleKeyPress("female")}
				role="button"
				tabIndex={0}
				style={{
					color:
						selected === "female"
							? "var(--tgui--button_color)"
							: "var(--tgui--secondary_hint_color)",
				}}
			>
				<Subheadline
					level="2"
					weight="3"
					style={{ textAlign: "center", fontWeight: "500" }}
				>
					👩<br />
					{t("picker.sexSelector.female")}
				</Subheadline>
				<div
					className={
						selected === "female" ? "indicator indicator-selected" : "indicator"
					}
				/>
			</div>

			<div className="spacer" />
		</div>
	);
};

export default PickerSexSelector;
