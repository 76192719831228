import { getComponentForPath } from "../routes";
import useStore from "./useStore";

export const useNavigation = () => {
	const { pushLocation, popLocation, replaceLocation } = useStore();
	const activeTab = useStore((state) => state.activeTab);
	const currentLocation = useStore(
		(state) =>
			state.locations[activeTab]?.[state.locations[activeTab].length - 1],
	);

	return {
		push: (pathname: string, props?: any) => {
			console.log("push", pathname, props);
			pushLocation(props?.tab || activeTab, {
				id: pathname,
				component: getComponentForPath(pathname),
				props,
				location: { pathname, state: props },
			});
		},
		replace: (pathname: string, props?: any) => {
			console.log("replace", pathname, props);
			replaceLocation(props?.tab || activeTab, {
				id: pathname,
				component: getComponentForPath(pathname),
				props,
				location: { pathname, state: props },
			});
		},
		pop: () => {
			console.log("pop");
			popLocation(activeTab);
		},
		currentLocation,
		activeTab,
	};
};
