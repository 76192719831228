const IconPhotoUpload: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<circle cx="20" cy="19" r="6" strokeWidth="2" stroke="currentColor" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5514 5.61024C13.0688 4.62044 14.0932 4 15.2101 4H24.7899C25.9068 4 26.9312 4.62044 27.4486 5.61024L28.138 6.92906C28.8126 8.21967 30.0496 8.89108 31.1776 9.11512C33.9276 9.66132 36 12.0909 36 15V26C36 29.3137 33.3137 32 30 32H15C14.4477 32 14 32.4477 14 33C14 33.5523 14.4477 34 15 34H30C34.4183 34 38 30.4183 38 26V15C38 11.1181 35.2351 7.88194 31.5672 7.15344C30.8747 7.01591 30.2375 6.62822 29.9104 6.00255L29.221 4.68373C28.3587 3.03406 26.6514 2 24.7899 2H15.2101C13.3486 2 11.6413 3.03406 10.779 4.68373L10.0896 6.00255C9.76252 6.62822 9.12527 7.01591 8.43281 7.15344C4.76489 7.88194 2 11.1181 2 15V21C2 21.5523 2.44772 22 3 22C3.55228 22 4 21.5523 4 21V15C4 12.0908 6.07242 9.66131 8.82243 9.11512C9.95045 8.89108 11.1874 8.21967 11.862 6.92906L12.5514 5.61024Z"
				fill="currentColor"
			/>
			<circle cx="31" cy="14" r="2" fill="currentColor" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 24C5.44772 24 5 24.4477 5 25V29H1C0.447715 29 0 29.4477 0 30C0 30.5523 0.447715 31 1 31H5V35C5 35.5523 5.44772 36 6 36C6.55228 36 7 35.5523 7 35V31H11C11.5523 31 12 30.5523 12 30C12 29.4477 11.5523 29 11 29H7V25C7 24.4477 6.55228 24 6 24Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default IconPhotoUpload;
