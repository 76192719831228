import { api } from "./client";
import type {
	ExportSticker,
	Gallery,
	GiftResponse,
	InferenceResponse,
	InvoiceCreateResponse,
	MediaUploadResponse,
	ModelCategoriesResponse,
	ModelCategory,
	Prices,
	ShareToChatResponse,
	Task,
	User,
} from "./types";

export const fetchProfile = async (): Promise<{
	data: User;
	status: number;
}> => {
	return api.get<User>("/profile");
};

export const fetchPrices = async (): Promise<{
	data: Prices;
	status: number;
}> => {
	return api.get<Prices>("/prices");
};

export const fetchModels = async (
	type: "discover" | "stickers",
): Promise<{ data: InferenceResponse; status: number }> => {
	const searchParams = new URLSearchParams();

	searchParams.set("type", type === "discover" ? "photo" : "meme");

	return api.get<InferenceResponse>(`/styles?${searchParams.toString()}`);
};

export const fetchCategories = async (): Promise<{
	data: ModelCategoriesResponse;
	status: number;
}> => {
	return api.get<ModelCategoriesResponse>("/categories");
};

export const uploadFile = async (
	file: File,
	signal?: AbortSignal,
): Promise<{ data: MediaUploadResponse; status: number }> => {
	const formData = new FormData();
	formData.append("file", file);

	return api.post<MediaUploadResponse>("/upload", formData, {
		signal,
	});
};

export const shareToChat = async (
	photo_id: number,
): Promise<{ data: ShareToChatResponse; status: number }> => {
	return api.post<ShareToChatResponse>(`/share?photo_id=${photo_id}`);
};

export const postTasks = async (
	styleId: number,
	photoId: number | number[],
	gender: string | string[],
	isOverlay: boolean,
): Promise<{ data: Task; status: number }> => {
	const photoId1 = Array.isArray(photoId) ? photoId[0] : photoId;
	const photoId2 = Array.isArray(photoId) ? photoId[1] : null;

	const gender1 = Array.isArray(gender) ? gender[0] : gender;
	const gender2 = Array.isArray(gender) ? gender[1] : null;

	return api.post<Task>("/tasks", {
		style_id: styleId,
		photo_id: photoId1,
		photo2_id: photoId2,
		gender: gender1,
		gender2: gender2,
		overlay: isOverlay,
	});
};

export const generateCategoryStickers = async (
	photoId: number | number[],
	categoryId: number,
	gender: string | string[],
	isOverlay: boolean,
): Promise<{ data: Task; status: number }> => {
	const photoId1 = Array.isArray(photoId) ? photoId[0] : photoId;
	const photoId2 = Array.isArray(photoId) ? photoId[1] : null;

	const gender1 = Array.isArray(gender) ? gender[0] : gender;
	const gender2 = Array.isArray(gender) ? gender[1] : null;

	return api.post<Task>("/generate/category", {
		photo_id: photoId1,
		photo2_id: photoId2,
		category_id: categoryId,
		gender: gender1,
		gender2: gender2,
		overlay: isOverlay,
	});
};

export const getTasks = async () => {
	return api.get("/tasks");
};

export const getGallery = async (): Promise<{
	data: Gallery;
	status: number;
}> => {
	return api.get<Gallery>("/gallery");
};

export const exportStickers = async (
	stickers: ExportSticker[],
	title: string,
): Promise<string> => {
	const response = await api.post<string>("/make-sticker-pack", {
		stickers: stickers.map((sticker) => ({
			photo_id: sticker.photo_id,
			emoji: sticker.emoji,
		})),
		name: title,
	});
	return response.data;
};

export const createInvoice = async (
	generations: number,
	stars: number,
): Promise<{ data: InvoiceCreateResponse; status: number }> => {
	return api.post<InvoiceCreateResponse>("/invoices", {
		title: "Top up generations",
		description: `${generations} generations`,
		amount: stars,
	});
};

export const sendGift = async (
	count: number,
): Promise<{ data: GiftResponse; status: number }> => {
	return api.post<GiftResponse>(`/gift?amount=${count}`);
};
