import { Button, Text } from "@telegram-apps/telegram-ui";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Animation } from "../../components/Animation";
import IconBolt from "../../icons/IconBolt";
import { IconShare } from "../../icons/IconShare";
import { useDiscoverStore } from "./useDiscoverStore";
import WebApp from "@twa-dev/sdk";

export const INVITE_FRIENDS_BONUS = 6;

export const InvitedFriendsModal: FC = () => {
	const { t } = useTranslation();
	const { profile, inviteBonus } = useDiscoverStore();

	const invitedFriendsCount = profile?.invited ?? 0;
	const firstGenerationCount = profile?.invited_generated ?? 0;

	const invitedFriendsText = invitedFriendsCount
		? t("invitedFriendsModal.invitedStats.joinedCount", {
				count: invitedFriendsCount,
			})
		: t("invitedFriendsModal.invitedStats.noJoins");

	const invitedFriendsGenerationText = firstGenerationCount
		? t("invitedFriendsModal.invitedStats.generationCount", {
				count: firstGenerationCount,
			})
		: t("invitedFriendsModal.invitedStats.noGenerations");

	const handleShare = () => {
		const url = `https://t.me/stickapp_bot/app?startapp=${profile?.id}`;
		const shareText = `\n${t("invitedFriendsModal.shareText.line1")}\n${t("invitedFriendsModal.shareText.line2")}`;

		WebApp.openTelegramLink(
			`https://t.me/share/url?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(url)}`,
		);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				padding: "30px 16px",
				gap: "24px",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "12px",
				}}
			>
				<Animation
					animationId="ticket"
					style={{
						width: "72px",
						height: "72px",
						minWidth: "72px",
						minHeight: "72px",
					}}
				/>
				<Text
					weight="1"
					style={{
						fontSize: "24px",
						lineHeight: "28px",
						letterSpacing: "0.07px",
					}}
				>
					{t("invitedFriendsModal.title")}
				</Text>
				<div className="text-center items-center font-regular text-[15px] leading-[20px]">
					{t("invitedFriendsModal.moreStickers")}{" "}
					<span className="font-[inherit] text-[15px] font-bold">
						{inviteBonus}
					</span>{" "}
					<IconBolt width={10} height={14.12} className="inline-block" />{" "}
					{t("invitedFriendsModal.eachTime")}
				</div>
				{profile?.invited !== undefined &&
					profile?.invited_generated !== undefined && (
						<Text
							weight="2"
							style={{
								fontSize: "13px",
								lineHeight: "16px",
								letterSpacing: "-0.08px",
								textAlign: "center",
							}}
						>
							{invitedFriendsText}
							{invitedFriendsCount > 0 && <br />}
							{invitedFriendsCount > 0 && invitedFriendsGenerationText}
						</Text>
					)}
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "8px",
					alignSelf: "stretch",
				}}
			>
				<Button
					size="s"
					mode="filled"
					style={{ borderRadius: "20px" }}
					loading={!profile?.id}
					before={<IconShare width={20} height={20} />}
					onClick={handleShare}
				>
					{t("invitedFriendsModal.shareButton")}
				</Button>
			</div>
		</div>
	);
};
