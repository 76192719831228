import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GalleryItem } from "../../api/types";
import useStore from "../../hooks/useStore";
import { Button } from "@telegram-apps/telegram-ui";
import { IconForward } from "../../icons/IconForward";
import { IconShare } from "../../icons/IconShare";
import { IconStories } from "../../icons/IconStories";
import { shareToChat, uploadFile } from "../../api/api";

import stick3Webp from "./stick3.webp";
import curveSvg from "./curve.svg";

import WebApp from "@twa-dev/sdk";
import { useDiscoverStore } from "../Discover/useDiscoverStore";
import { hapticClick } from "../../utils/haptic";
import { useNavigation } from "../../hooks/useLocation";

interface ResultStickerViewerProps {
	item: GalleryItem;
	onClose: () => void;
}

export const ResultStickerViewer: FC<ResultStickerViewerProps> = ({
	item,
	onClose,
}) => {
	const storyRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	const navigation = useNavigation();
	const { profile } = useDiscoverStore();
	const [isLoadingDownloading, setIsLoadingDownloading] = useState(false);
	const [isLoadingSending, setIsLoadingSending] = useState(false);
	const [isLoadingSharingStories, setIsLoadingSharingStories] = useState(false);

	const { setTabbarVisible } = useStore();

	useEffect(() => {
		setTabbarVisible(false);

		return () => {
			setTabbarVisible(true);
		};
	}, [setTabbarVisible]);

	const stickers =
		item.result.length > 6
			? item.result.filter((_, index) => index % 2 === 0).slice(0, 6)
			: item.result;

	const heightOfGrid = useMemo(() => {
		const windowHeight = window.innerHeight;
		const safeAreaInsetBottom = WebApp.safeAreaInset.bottom;
		return windowHeight - safeAreaInsetBottom - 150;
	}, []);

	/**
	 * Creates a canvas with a 2x3 grid of stickers
	 */
	const createStickersCanvas = async (): Promise<HTMLCanvasElement> => {
		// Create a canvas with dimensions 1920x1080
		const canvas = document.createElement("canvas");
		canvas.width = 1080;
		canvas.height = 1920;
		const ctx = canvas.getContext("2d");

		if (!ctx) {
			throw new Error("Could not get canvas context");
		}

		// Load background image
		try {
			const backgroundImage = new Image();

			const { details } = item;

			console.log("details", details);

			const styleId =
				details.requestType === "style"
					? `${details.request.style_id}`
					: `category_${details.request.category_id}`;

			backgroundImage.src = `/photos/${styleId}.webp`;
			await new Promise((resolve, reject) => {
				backgroundImage.onload = resolve;
				backgroundImage.onerror = reject;
			});

			// Calculate dimensions to maintain objectFit cover
			const imgRatio = backgroundImage.width / backgroundImage.height;
			const canvasRatio = canvas.width / canvas.height;

			let drawWidth: number;
			let drawHeight: number;
			let offsetX = 0;
			let offsetY = 0;

			if (imgRatio > canvasRatio) {
				// Image is wider than canvas (relative to their heights)
				drawHeight = canvas.height;
				drawWidth = canvas.height * imgRatio;
				offsetX = (canvas.width - drawWidth) / 2;
			} else {
				// Image is taller than canvas (relative to their widths)
				drawWidth = canvas.width;
				drawHeight = canvas.width / imgRatio;
				offsetY = (canvas.height - drawHeight) / 2;
			}

			// Draw background image with cover behavior
			ctx.drawImage(backgroundImage, offsetX, offsetY, drawWidth, drawHeight);
		} catch (error) {
			console.error("Failed to load background image:", error);
			// Fall back to black background if image fails to load
			ctx.fillStyle = "black";
			ctx.fillRect(0, 0, canvas.width, canvas.height);
		}

		// Add a semi-transparent black overlay to improve sticker visibility
		ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
		ctx.fillRect(0, 0, canvas.width, canvas.height);

		// Grid layout calculations
		const padding = 150; // 40px padding from borders
		const gridWidth = canvas.width - padding * 2;
		const gridHeight = canvas.height - padding * 2;

		// Calculate sticker dimensions - each sticker should be the same size
		const cols = 2;
		const rows = 3;
		const gap = 50; // Same gap as in the existing grid

		// Calculate sticker width and height accounting for gaps
		const stickerWidth = (gridWidth - gap * (cols - 1)) / cols;
		const stickerHeight = (gridHeight - gap * (rows - 1)) / rows;

		// Load all sticker images first and wait for them to complete
		const stickerImages = await Promise.all(
			stickers.map((sticker) => {
				return new Promise<HTMLImageElement>((resolve, reject) => {
					const img = new Image();
					img.crossOrigin = "anonymous";
					img.onload = () => resolve(img);
					img.onerror = () =>
						reject(new Error(`Failed to load image: ${sticker.url}`));
					img.src = sticker.url.replace(
						"https://stickersgen.online/photos/",
						"/photos/",
					);
				});
			}),
		);

		// Draw each sticker in the grid
		for (let row = 0; row < rows; row++) {
			for (let col = 0; col < cols; col++) {
				const index = row * cols + col;
				if (index >= stickers.length) continue;

				const img = stickerImages[index];

				// Calculate sticker position
				const x = padding + col * (stickerWidth + gap);
				const y = padding + row * (stickerHeight + gap);

				// Calculate the aspect ratio to maintain the image proportions
				const aspectRatio = img.width / img.height;
				let drawWidth = stickerWidth;
				let drawHeight = stickerHeight;

				// Adjust dimensions to maintain aspect ratio and fit within the allocated space
				if (aspectRatio > 1) {
					// Image is wider than tall
					drawHeight = drawWidth / aspectRatio;
				} else {
					// Image is taller than wide
					drawWidth = drawHeight * aspectRatio;
				}

				// Save the current state of the canvas
				ctx.save();

				// Set the center point for rotation and translate to it
				const centerX = x + stickerWidth / 2;
				const centerY = y + stickerHeight / 2;
				ctx.translate(centerX, centerY);

				// Rotate left column to the left, right column to the right
				const rotationDegrees = col % 2 === 0 ? -4 : 4;
				const rotationRadians = (rotationDegrees * Math.PI) / 180;
				ctx.rotate(rotationRadians);

				// Calculate the cell padding
				const cellPadding = 10;

				// Draw the image centered at origin (after translation)
				ctx.drawImage(
					img,
					-drawWidth / 2, // Center the image horizontally
					-drawHeight / 2, // Center the image vertically
					drawWidth - cellPadding * 2,
					drawHeight - cellPadding * 2,
				);

				// Restore the canvas state
				ctx.restore();

				// Debug: Draw a border around the sticker cell (optional, can be removed)
				// ctx.strokeStyle = "rgba(255, 255, 255, 0.2)";
				// ctx.strokeRect(x, y, stickerWidth, stickerHeight);
			}
		}

		// Add logo mask with stick3Webp
		try {
			const logoMask = new Image();
			logoMask.src = stick3Webp;
			await new Promise((resolve, reject) => {
				logoMask.onload = resolve;
				logoMask.onerror = reject;
			});

			// Position the logo mask at the bottom of the canvas
			const logoWidth = canvas.width * 0.25; // 20% of canvas width
			const logoHeight = (logoWidth / logoMask.width) * logoMask.height;
			const logoX = (canvas.width - logoWidth) * 0.9; //
			const logoY = canvas.height - logoHeight - 620; // 500px from bottom

			ctx.drawImage(logoMask, logoX, logoY, logoWidth, logoHeight);
		} catch (error) {
			console.error("Failed to load logo mask:", error);
		}

		// Add curve mask
		try {
			const logoMask = new Image();
			logoMask.src = curveSvg;
			await new Promise((resolve, reject) => {
				logoMask.onload = resolve;
				logoMask.onerror = reject;
			});

			// Position the logo mask at the bottom of the canvas
			const logoWidth = canvas.width * 0.65; // 20% of canvas width
			const logoHeight = (logoWidth / logoMask.width) * logoMask.height;
			const logoX = (canvas.width - logoWidth) * 0.2; //
			const logoY = canvas.height - logoHeight - 630; // 500px from bottom

			ctx.drawImage(logoMask, logoX, logoY, logoWidth, logoHeight);
		} catch (error) {
			console.error("Failed to load logo mask:", error);
		}

		return canvas;
	};

	const handleShareToStories = async () => {
		try {
			setIsLoadingSharingStories(true);
			// Create the canvas with the sticker grid
			const canvas = await createStickersCanvas();

			// Convert canvas to blob
			const blob = await new Promise<Blob>((resolve) => {
				canvas.toBlob(
					(blob) => {
						if (blob) resolve(blob);
						else resolve(new Blob([]));
					},
					"image/png",
					0.95,
				);
			});

			// Upload the blob as a file
			const response = await uploadFile(
				new File([blob], `${item.style.name}.png`, { type: "image/png" }),
			);

			const link = `https://t.me/stickapp_bot/app?startapp=${profile?.id}`;

			WebApp.shareToStory(response.data.url, {
				text: t("shareToStory.text", { link }),
				widget_link: {
					url: link,
					name: t("shareToStory.button"),
				},
			});
		} catch (error) {
			console.error("Error sharing to stories:", error);
		} finally {
			setIsLoadingSharingStories(false);
		}
	};

	const handleSendToChat = async () => {
		setIsLoadingSending(true);
		try {
			// Create the canvas with the sticker grid
			const canvas = await createStickersCanvas();

			// Convert canvas to blob
			const blob = await new Promise<Blob>((resolve) => {
				canvas.toBlob((blob) => {
					if (blob) resolve(blob);
					else resolve(new Blob([]));
				});
			});

			// Upload the blob as a file
			const response = await uploadFile(
				new File([blob], "story.png", { type: "image/png" }),
			);

			console.log("uploadFile", response.data);

			const shareResponse = await shareToChat(response.data.photo_id);

			console.log("shareResponse", shareResponse.data);

			WebApp.shareMessage(shareResponse.data.id);
		} catch (error) {
			console.error("Error sending to chat:", error);
		} finally {
			setIsLoadingSending(false);
		}
	};

	const handleOpenStickerPicker = (taskId: number) => {
		hapticClick("light");
		navigation.push("/stickers/picker", {
			taskId,
		});
	};

	const handleOpenViewer = (item: GalleryItem, index: number) => {
		hapticClick("light");

		navigation.push("/profile/result", {
			item,
			initialIndex: index,
			onClose: () => {
				navigation.pop();
			},
		});
	};

	const { details } = item;

	return (
		<div
			ref={storyRef}
			className="relative z-[1] bg-black w-full h-[calc(100vh-var(--safe-area-inset-bottom))]"
			style={{
				backgroundImage: `url(/photos/${
					details.requestType === "style"
						? details.request.style_id
						: `category_${details.request.category_id}`
				}.webp)`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
			}}
		>
			<div
				id="sticker-grid"
				className="flex flex-wrap gap-[15px] pt-[0] px-[16px]"
				style={{
					height: heightOfGrid,
				}}
			>
				{stickers.map((sticker, index) => (
					// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
					<div
						key={sticker.photo_id}
						className="flex-[1_1_calc(50%-7.5px)] flex items-center justify-center rounded-lg overflow-hidden"
						onClick={() => {
							const realIndex = item.result.findIndex(
								(result) => result.photo_id === sticker.photo_id,
							);

							if (realIndex !== -1) {
								handleOpenViewer(item, realIndex);
							}
						}}
					>
						<img
							src={sticker.url.replace(
								"https://stickersgen.online/photos/",
								"/photos/",
							)}
							style={{
								transform: `rotate(${index % 2 === 0 ? -4 : 4}deg)`,
								height: heightOfGrid / 3,
								objectFit: "contain",
							}}
							alt={`Sticker ${sticker.photo_id}`}
							className="w-full h-full p-[10px] object-contain"
						/>
					</div>
				))}
			</div>

			<div
				data-html2canvas-ignore
				className="fixed bottom-[var(--safe-area-inset-bottom)] h-fit left-0 right-0 px-[16px] py-[16px]"
			>
				<Button
					className="w-full !bg-white !text-black items-center !gap-[4px]"
					before={<IconForward width={24} height={24} />}
					onClick={() => handleOpenStickerPicker(item.id)}
					loading={isLoadingDownloading}
				>
					{t("resultStickerViewer.exportToTelegram")}
				</Button>
				<div className="flex gap-[8px] mt-[8px]">
					<Button
						className="w-full !bg-[#007AFF] !text-white items-center !gap-[4px]"
						before={<IconShare width={24} height={24} />}
						onClick={handleSendToChat}
						loading={isLoadingSending}
					>
						{t("resultStickerViewer.send")}
					</Button>
					<Button
						className="w-full !bg-[#007AFF] !text-white items-center !gap-[4px]"
						before={<IconStories />}
						onClick={handleShareToStories}
						loading={isLoadingSharingStories}
					>
						{t("resultStickerViewer.shareToStories")}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ResultStickerViewer;
