import { Button, Headline, Text } from "@telegram-apps/telegram-ui";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { GalleryItem } from "../../api/types";
import "./Profile.css";
import clsx from "clsx";
import WebSocketService from "../../api/websocket";
import CachedImage from "../../components/CachedImage/CachedImage";
import { useNavigation } from "../../hooks/useLocation";
import { IconAdd } from "../../icons/IconAdd";
import { IconEllipses20 } from "../../icons/IconEllipses20";
import { IconForward } from "../../icons/IconForward";
import { hapticClick } from "../../utils/haptic";
import { Animation } from "../../components/Animation";
import { useGalleryStore } from "../StickersPicker/useGalleryStore";
import { LazyGallery } from "../../components/LazyGallery";
import { TabType } from "../../types/tabs";
import useStore from "../../hooks/useStore";

import Image1Png from "./skeletons/1.png";
import Image2Png from "./skeletons/2.png";
import Image3Png from "./skeletons/3.png";
import Image4Png from "./skeletons/4.png";
import WebApp from "@twa-dev/sdk";
import { isCurrentUserTestUser } from "../../utils/test";
import { useDiscoverStore } from "../Discover/useDiscoverStore";

const Profile = () => {
	const { t } = useTranslation();
	const { rawItems, error, isInitialLoading, fetchGallery } = useGalleryStore();

	const [openPopoverModelId, setOpenPopoverModelId] = useState<number | null>(
		null,
	);

	const { categories, models } = useDiscoverStore();
	const { setSelectedItems } = useGalleryStore();

	const { setActiveTab } = useStore();

	let haveError = false;

	const filteredItems = rawItems.filter((item) => {
		if (item.status === "error" && !haveError) {
			haveError = true;
			return true;
		}

		return item.status === "done" || item.status === "pending";
	});

	const doneItems = rawItems.filter((item) => item.status === "done");

	const navigation = useNavigation();

	const handleOpenViewer = (item: GalleryItem, index: number) => {
		hapticClick("light");

		navigation.push("/profile/result", {
			item,
			initialIndex: index,
			onClose: () => {
				navigation.pop();
			},
		});
	};

	const handleOpenStickerViewer = (item: GalleryItem) => {
		hapticClick("light");

		navigation.push("/profile/stickerset", {
			item,
			onClose: () => {
				navigation.pop();
			},
		});
	};

	const handleRetryClick = (item: GalleryItem) => {
		if (item.details.requestType === "style") {
			const { request } = item.details;

			const style = models.find((model) => model.id === request.style_id);

			if (!style) {
				return;
			}

			const twoPhoto = style.two_photo;

			navigation.push("/discover/instruction/picker", {
				styleId: request.style_id,
				twoPhoto,
			});
		} else {
			const { request } = item.details;

			navigation.push("/discover/instruction/picker", {
				category: request.category_id,
			});
		}
	};

	const initLoading = isInitialLoading && rawItems.length === 0;
	const hasError = error && rawItems.length === 0;

	useEffect(() => {
		const wsService = WebSocketService.getInstance();

		fetchGallery();

		const handleTaskStatus = (data: { task_id: number; status: string }) => {
			fetchGallery();
		};

		wsService.on("task_status", handleTaskStatus);

		return () => {
			wsService.off("task_status", handleTaskStatus);
		};
	}, [fetchGallery]);

	const handleOpenInstruction = (item: GalleryItem) => {
		if (item.details.requestType === "style") {
			const { request } = item.details;

			const style = models.find((model) => model.id === request.style_id);

			if (!style) {
				return;
			}

			const twoPhoto = style.two_photo;

			navigation.push("/discover/instruction", {
				styleId: request.style_id,
				twoPhoto,
			});
		} else {
			const { request } = item.details;

			navigation.push("/discover/instruction", {
				category: request.category_id,
			});
		}
	};

	const handleOpenStickerPicker = (taskId: number) => {
		hapticClick("light");
		navigation.push("/stickers/picker", {
			taskId,
		});
	};

	const handleClickOutside = useCallback((event: MouseEvent) => {
		const target = event.target as HTMLElement;
		if (!target.closest(".popover-trigger")) {
			setOpenPopoverModelId(null);
		}
	}, []);

	useEffect(() => {
		if (openPopoverModelId) {
			document.addEventListener("click", handleClickOutside);
			return () => document.removeEventListener("click", handleClickOutside);
		}
	}, [openPopoverModelId, handleClickOutside]);

	useEffect(() => {
		return () => {
			setSelectedItems([]);
		};
	}, [setSelectedItems]);

	if (rawItems.length === 0) {
		return (
			<div className="flex flex-col items-center justify-center h-[calc(100vh-var(--tab-bar-height))]">
				<div className="flex flex-col items-center justify p-[60px]">
					<Animation
						animationId="hands"
						loop={false}
						style={{ width: "112px", height: "112px" }}
					/>
					<Headline
						weight="1"
						style={{ color: "inherit", textAlign: "center" }}
					>
						{t("profile.resultsStoredHere")}
					</Headline>
				</div>
			</div>
		);
	}

	const isAvailableStickersExport = doneItems.length > 0;

	return (
		<div
			className="container enable-scrollbar"
			style={{
				paddingBottom: "calc(var(--tab-bar-height))",
				paddingLeft: 0,
				paddingRight: 0,
			}}
		>
			<div className="px-[16px] flex gap-2">
				{isAvailableStickersExport ? (
					<Button
						size="s"
						onClick={(e) => {
							hapticClick("light");

							handleOpenStickerPicker(doneItems[0].id);
						}}
						before={<IconAdd />}
						style={{ height: "36px" }}
						className="mb-[16px] flex-1"
					>
						{t("profile.exportTelegramStickers")}
					</Button>
				) : (
					<Button
						size="s"
						onClick={(e) => {
							hapticClick("light");

							navigation.push("/discover", { tab: TabType.STICKERS });
							setActiveTab(TabType.STICKERS);
						}}
						before={<IconAdd />}
						style={{ height: "36px" }}
						className="mb-[16px] flex-1"
					>
						{t("profile.generateAvatars")}
					</Button>
				)}
			</div>

			{hasError && (
				<div
					style={{
						flexGrow: 1,
						display: "flex",
						flexDirection: "column",
						gap: 8,
						padding: "0 16px",
						color: "var(--tgui--destructive_text_color)",
					}}
				>
					<Headline weight="1" style={{ color: "inherit" }}>
						{t("profile.error.title")}
					</Headline>
					{error.status && (
						<Text weight="2" style={{ color: "inherit" }}>
							{error.status}
						</Text>
					)}
					<Text weight="3" style={{ color: "inherit" }}>
						{error.message}
					</Text>
				</div>
			)}

			<div className="h-fit">
				{(!initLoading || filteredItems.length > 0) &&
					!hasError &&
					filteredItems &&
					filteredItems.map((model: GalleryItem) => (
						<div key={model.id} className="mb-[16px]">
							<Headline
								weight="2"
								className="flex items-center justify-between gap-[2px] !mb-[8px] px-[16px]"
							>
								{model.details.requestType === "style"
									? model.details.request.style_name
									: model.details.request.category_name}
								{isCurrentUserTestUser() &&
									` (${model.id}) [${
										model.details.requestType === "style"
											? model.details.request.style_id
											: model.details.request.category_id
									}]`}
								{(model.status === "running" || model.status === "pending") && (
									<Text weight="3" className="text-hint ml-[4px]">
										{t("profile.error.generating")}
									</Text>
								)}
								{model.status === "error" && (
									<Text
										weight="3"
										className="text-destructive-text-color ml-[4px]"
									>
										{t("profile.error.failed")}
									</Text>
								)}
								{model.status === "done" && model?.style?.type !== "photo" && (
									<div className="relative">
										{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
										<div
											className={clsx(
												"w-[32px] h-[32px] flex items-center justify-center rounded-full overflow-hidden",
												"cursor-pointer",
												"relative group",
												"select-none",
												"popover-trigger",
											)}
											onClick={(e) => {
												e.stopPropagation();
												setOpenPopoverModelId(model.id);
											}}
										>
											<div className="absolute inset-0 opacity-20 blur-10 group-hover:bg-link group-active:bg-link" />
											<IconEllipses20 />
										</div>
										{openPopoverModelId === model.id && (
											<div
												className={clsx(
													"absolute z-[1000] right-0 top-[34px]",
													"bg-background shadow-popover",
													"rounded-[20px] overflow-hidden",
													"flex flex-col",
													"w-fit h-fit",
													"text-[17px] leading-[22px] font-normal",
													"popover-trigger",
												)}
											>
												{/* <div
													className={clsx(
														"flex items-center gap-[16px] px-[16px] py-[8px] text-link",
														"cursor-pointer",
													)}
												>
													<IconArrowDown />
													<span className="whitespace-nowrap text-text">
														{t("profile.actions.downloadAll")}
													</span>
												</div> */}
												{/* <div className="h-[1px] bg-hint w-[calc(100%-16px)] ml-[16px] opacity-[0.15]" />s */}
												{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
												<div
													className={clsx(
														"flex items-center gap-[16px] px-[16px] py-[10px] text-link",
														"cursor-pointer",
													)}
													onClick={() => handleOpenStickerPicker(model.id)}
												>
													<IconForward />
													<span className="whitespace-nowrap text-text">
														{t("profile.actions.exportToTelegram")}
													</span>
												</div>
											</div>
										)}
									</div>
								)}
							</Headline>
							{model.status === "error" && (
								<div className="flex px-[16px]">
									<div className="flex flex-col gap-[8px]">
										<div className="text-text text-[15px] leading-[20px]">
											{t("profile.error.couldNotCreate")}
										</div>
										<div className="flex">
											<Button
												size="s"
												onClick={() => handleRetryClick(model)}
												style={{ height: "28px" }}
											>
												{t("profile.actions.retry")}
											</Button>
											<Button
												size="s"
												mode="outline"
												onClick={() => handleOpenInstruction(model)}
												style={{
													height: "28px",
													marginLeft: "8px",
												}}
											>
												{t("profile.actions.instructions")}
											</Button>
										</div>
									</div>
								</div>
							)}
							{(model.status === "running" || model.status === "pending") && (
								<SkeletonImagesRow />
							)}
							{model.status === "done" && (
								<LazyGallery
									model={model}
									handleOpenViewer={
										model.style.type === "photo"
											? handleOpenViewer
											: handleOpenStickerViewer
									}
								/>
							)}
						</div>
					))}
			</div>
			{initLoading && (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "16px",
						flexGrow: 1,
						overflow: "hidden",
					}}
				>
					{Array.from({ length: 5 }).map((_, itemIndex) => (
						<div key={`skeleton-row-${itemIndex}`} className="profile-item">
							<div
								className="skeleton"
								style={{
									alignSelf: "flex-start",
									margin: "2px 16px",
									width: "125px",
									height: "24px",
									borderRadius: "8px",
								}}
							/>
							<SkeletonLoadingImagesRow />
						</div>
					))}
				</div>
			)}
		</div>
	);
};

const IMAGES = [
	{
		gradient:
			"linear-gradient(234.49deg, rgba(255, 221, 0, 0) 17.54%, rgba(255, 221, 0, 0) 24.34%, rgba(255, 221, 0, 0.06) 38.33%, rgba(255, 221, 0, 0.1) 54.46%, rgba(255, 221, 0, 0.074) 70.73%, rgba(255, 221, 0, 0) 84.72%, rgba(255, 221, 0, 0) 95.12%)",
		image: Image1Png,
	},
	{
		gradient:
			"linear-gradient(234.49deg, rgba(255, 221, 0, 0) 17.54%, rgba(255, 221, 0, 0) 24.34%, rgba(255, 221, 0, 0.06) 38.33%, rgba(255, 221, 0, 0.1) 54.46%, rgba(255, 221, 0, 0.074) 70.73%, rgba(255, 221, 0, 0) 84.72%, rgba(255, 221, 0, 0) 95.12%)",
		image: Image2Png,
	},
	{
		gradient:
			"linear-gradient(234.49deg, rgba(255, 221, 0, 0) 17.54%, rgba(255, 221, 0, 0) 24.34%, rgba(255, 221, 0, 0.06) 38.33%, rgba(255, 221, 0, 0.1) 54.46%, rgba(255, 221, 0, 0.074) 70.73%, rgba(255, 221, 0, 0) 84.72%, rgba(255, 221, 0, 0) 95.12%)",
		image: Image3Png,
	},
	{
		gradient:
			"linear-gradient(234.49deg, rgba(255, 221, 0, 0) 17.54%, rgba(255, 221, 0, 0) 24.34%, rgba(255, 221, 0, 0.06) 38.33%, rgba(255, 221, 0, 0.1) 54.46%, rgba(255, 221, 0, 0.074) 70.73%, rgba(255, 221, 0, 0) 84.72%, rgba(255, 221, 0, 0) 95.12%)",
		image: Image4Png,
	},
];

const SkeletonImagesRow: React.FC = () => {
	const textColor = WebApp.themeParams.text_color;
	const [increment, setIncrement] = useState(0);

	const style = {
		backgroundImage: `linear-gradient(90deg,${textColor}0F 25%,${textColor}26 37%,${textColor}0F 63%)`,
	};

	useEffect(() => {
		setInterval(() => {
			setIncrement((prev) => prev + 1);
		}, 1000);
	}, []);

	return (
		<div
			className="carousel-container px-[16px]"
			style={{ overflowX: "hidden" }}
		>
			{Array.from({ length: 5 }).map((_, index) => {
				const image = IMAGES[index % IMAGES.length];
				return (
					<div
						key={`skeleton-image-${index}-${image.image}`}
						className="image-container skeleton"
						style={style}
					>
						<CachedImage
							alt=""
							src={image.image}
							className="w-[50px] h-[50px]"
						/>
					</div>
				);
			})}
		</div>
	);
};

const SkeletonLoadingImagesRow: React.FC = () => {
	return (
		<div
			className="carousel-container px-[16px]"
			style={{ overflowX: "hidden" }}
		>
			{Array.from({ length: 5 }).map((_, index) => (
				<Button
					key={index}
					mode="plain"
					className="image-container skeleton"
					style={{ cursor: "default" }}
				/>
			))}
		</div>
	);
};

export default Profile;
