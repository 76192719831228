import WebApp from "@twa-dev/sdk";

export const TEST_USERS = [
	314422618, 559212240, 384334182, 3346330, 381405, 301897297, 533440211,
	122374628, 217292451, 132407332, 358278652, 1780400,
];

export const isTestUser = (userId: number) => {
	return TEST_USERS.includes(userId);
};

export const isCurrentUserTestUser = () => {
	return WebApp.initDataUnsafe.user
		? isTestUser(WebApp.initDataUnsafe.user.id)
		: false;
};
