import DiscoverScreen from "../screens/Discover/Discover";
import PickerScreen from "../screens/Picker/Picker";
import UploadInstructionScreen from "../screens/UploadInstruction/UploadInstruction";
import ResultImageViewer from "../screens/ResultImageViewer/ResultImageViewer";
import ProfileScreen from "../screens/Profile/Profile";
import StickersScreen from "../screens/Stickers/Stickers";
import NotFoundScreen from "../screens/NotFound";
import StickerPickerScreen from "../screens/StickersPicker/StickersPicker";
import StickerPackScreen from "../screens/StickerPack/StickerPack";
import ResultStickerViewer from "../screens/ResultStickerViewer/ResultStickerViewer";

export const routes: Record<string, React.ComponentType<any>> = {
	"/discover": DiscoverScreen,
	"/discover/instruction/picker": PickerScreen,
	"/discover/instruction": UploadInstructionScreen,
	"/discover/instruction/picker/result": ResultImageViewer,
	"/stickers": StickersScreen,
	"/stickers/picker": StickerPickerScreen,
	"/stickers/pack": StickerPackScreen,
	"/profile": ProfileScreen,
	"/profile/result": ResultImageViewer,
	"/profile/stickerset": ResultStickerViewer,
};

export const getComponentForPath = (pathname: string) => {
	return routes[pathname] || NotFoundScreen;
};
