import type { CSSProperties } from "react";
import { useCallback, useEffect, useState } from "react";
import "./CachedImage.css";
import clsx from "clsx";

import { useIntersectionObserver } from "usehooks-ts";

interface CachedImageProps {
	className?: string;
	src: string;
	alt: string;
	style?: React.CSSProperties;
	forceNotLazy?: boolean;
	threshold?: number;
	onClick?: () => void;
}

const CachedImage = (props: CachedImageProps) => {
	const [isViewed, setIsViewed] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const { isIntersecting, ref } = useIntersectionObserver({
		threshold: 0,
	});

	useEffect(() => {
		if (isIntersecting) {
			setIsViewed(true);
		}
	}, [isIntersecting]);

	const handleImageLoad = useCallback(() => {
		setTimeout(() => {
			setIsLoaded(true);
		}, 300);
	}, []);

	return (
		// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div
			ref={ref}
			className={isLoaded ? "" : "cached-image-skeleton"}
			style={{ ...props.style }}
			onClick={props.onClick}
		>
			{isViewed && (
				<img
					ref={ref}
					src={props.src}
					alt={props.alt}
					style={
						isLoaded
							? props.style
							: {
									opacity: 0,
								}
					}
					className={clsx(
						props.className,
						"transition-opacity duration-300 ease-in-out",
					)}
					onLoad={handleImageLoad}
				/>
			)}
			{!isLoaded && <div className="cached-image-skeleton" />}
		</div>
	);
};

export default CachedImage;
