import {
	Button,
	Caption,
	Spinner,
	Text,
	Title,
} from "@telegram-apps/telegram-ui";
import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createInvoice } from "../../api/api";
import { Animation } from "../../components/Animation";
import { formatter } from "../../helpers/formatter";
import IconBolt from "../../icons/IconBolt";
import { hapticClick } from "../../utils/haptic";
import { IconStar } from "../../icons/IconStar";
import WebApp from "@twa-dev/sdk";
import { useDiscoverStore } from "../Discover/useDiscoverStore";
import type { Price } from "../../api/types";

interface TopUpOption {
	generations: number;
	stars: number;
}

interface TopUpProps {
	balance: number;
	onClose?: (isSuccessPurchase: boolean) => void;
}

const TopUp: FC<TopUpProps> = (props) => {
	const { t } = useTranslation();
	const { prices } = useDiscoverStore();

	const [loadingOption, setLoadingOption] = useState<Price | null>(null);

	const buy = async (option: Price) => {
		hapticClick("medium");

		setLoadingOption(option);
		setLoadingOption(null);

		WebApp.openInvoice(option.invoice_url, (event) => {
			if (event === "paid") {
				props.onClose?.(true);
			}
		});
	};

	return (
		<div>
			<div
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					maxWidth: "100vw",
					padding: "16px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<Caption
						weight="3"
						style={{ lineHeight: "16px", letterSpacing: "-0.08px" }}
					>
						{t("topUp.balance")}
					</Caption>
					<div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
						<IconBolt width={12} height={18} />
						<Text
							weight="1"
							style={{
								fontSize: "17px",
								lineHeight: "22px",
								letterSpacing: "-0.4px",
							}}
						>
							{formatter.format(props.balance)}
						</Text>
					</div>
				</div>
			</div>
			<div
				style={{
					paddingTop: "30px",
					paddingBottom: "16px",
					paddingLeft: "16px",
					paddingRight: "16px",
					display: "flex",
					gap: "24px",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						gap: "8px",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Animation
						animationId="bolt"
						style={{
							width: "64px",
							height: "82px",
							minWidth: "64px",
							minHeight: "82px",
						}}
					/>
					<Title level="2" weight="1">
						{t("topUp.title")}
					</Title>
				</div>
				<div
					style={{
						display: "flex",
						gap: "16px",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					{prices?.map((price) => {
						const isLoading = loadingOption?.invoice_url === price.invoice_url;

						return (
							<Button
								key={price.invoice_url}
								onClick={() => buy(price)}
								size="m"
								mode={"filled"}
								className="!bg-background"
								loading={isLoading}
							>
								<div
									style={{
										width: "calc(100vw - 16px - 16px - 16px - 16px)",
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
									}}
								>
									{isLoading ? (
										<Spinner size="s" />
									) : (
										<>
											<div
												style={{
													display: "flex",
													gap: "4px",
													alignItems: "center",
												}}
											>
												<IconBolt width={17} height={24} />
												<Title
													weight="1"
													style={{
														color: "var(--tgui--text_color)",
														fontSize: "20px",
														lineHeight: "20px",
														letterSpacing: "-0.45px",
													}}
												>
													{price.lightning}
												</Title>
											</div>
											<div
												style={{
													display: "flex",
													gap: "4px",
													alignItems: "center",
												}}
											>
												<Text
													weight="3"
													style={{ color: "var(--tgui--text_color)" }}
												>
													{price.stars}
												</Text>
												<IconStar />
											</div>
										</>
									)}
								</div>
							</Button>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default TopUp;
