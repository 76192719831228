const IconDeny: React.FC = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="10" cy="10" r="10" fill="#FF423E" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.05033 5.05025C4.65981 5.44078 4.65981 6.07394 5.05033 6.46447L8.58587 10L5.05033 13.5355C4.65981 13.9261 4.65981 14.5592 5.05033 14.9497C5.44086 15.3403 6.07402 15.3403 6.46455 14.9497L10.0001 11.4142L13.5356 14.9497C13.9261 15.3403 14.5593 15.3403 14.9498 14.9497C15.3404 14.5592 15.3404 13.9261 14.9498 13.5355L11.4143 10L14.9498 6.46447C15.3404 6.07394 15.3404 5.44078 14.9498 5.05025C14.5593 4.65973 13.9261 4.65973 13.5356 5.05025L10.0001 8.58579L6.46455 5.05025C6.07402 4.65973 5.44086 4.65973 5.05033 5.05025Z"
				fill="white"
			/>
		</svg>
	);
};

export default IconDeny;
