import {
	Button,
	CompactPagination,
	IconButton,
} from "@telegram-apps/telegram-ui";
import { CompactPaginationItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/CompactPagination/components/CompactPaginationItem/CompactPaginationItem";
import type { FC } from "react";
import { useEffect, useCallback, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import type { GalleryItem } from "../../api/types";
import CachedImage from "../../components/CachedImage/CachedImage";
import useStore from "../../hooks/useStore";
import IconChevronLeft from "../../icons/IconChevronLeft";
import IconChevronRight from "../../icons/IconChevronRight";
import IconClose from "../../icons/IconClose";
import IconDownload from "../../icons/IconDownload";
import IconStory from "../../icons/IconStory";
import WebApp from "@twa-dev/sdk";
import { useDiscoverStore } from "../Discover/useDiscoverStore";
import { useTranslation } from "react-i18next";

interface ResultImageViewerProps {
	item: GalleryItem;
	initialIndex?: number;
	onClose: () => void;
}

const ResultImageViewer: FC<ResultImageViewerProps> = ({
	item,
	initialIndex = 0,
	onClose,
}) => {
	const { t } = useTranslation();
	const { profile } = useDiscoverStore();
	const { setTabbarVisible } = useStore();
	const [currentIndex, setCurrentIndex] = useState(initialIndex);

	const [emblaRef, emblaApi] = useEmblaCarousel({
		startIndex: initialIndex,
		align: "center",
		dragFree: false,
		containScroll: "trimSnaps",
	});

	const shareStory = () => {
		const currentImage = item.result?.[currentIndex];
		if (!currentImage) return;

		const link = `https://t.me/stickapp_bot/app?startapp=${profile?.id}`;

		WebApp.shareToStory(currentImage.url, {
			text: t("shareToStory.text", { link }),
			widget_link: {
				url: link,
				name: t("shareToStory.button"),
			},
		});
	};

	const onSaveButtonClick = () => {
		const currentImageUrl = item.result?.[currentIndex];
		if (!currentImageUrl) return;

		try {
			const date = new Date();
			const formattedDate = `${date.getFullYear()}_${String(date.getMonth() + 1).padStart(2, "0")}_${String(date.getDate()).padStart(2, "0")}_${String(date.getHours()).padStart(2, "0")}_${String(date.getMinutes()).padStart(2, "0")}`;

			const extension = currentImageUrl.url.split(".").pop();

			const fileName = `${item.style.name.replaceAll(" ", "_")}_${currentIndex + 1}(${formattedDate}).${extension}`;
			WebApp.downloadFile({
				url: currentImageUrl.url,
				file_name: fileName,
			});
		} catch (error) {
			console.error("Download error:", error);
			if (
				error instanceof Error &&
				error.message === "WebAppDownloadFilePopupOpened"
			) {
				alert(t("resultImageViewer.downloadError.reloadAndTry"));
			} else {
				alert(t("resultImageViewer.downloadError.failed"));
			}
		}
	};

	const scrollTo = useCallback(
		(index: number) => {
			if (emblaApi) {
				emblaApi.scrollTo(index);
			}
		},
		[emblaApi],
	);

	useEffect(() => {
		if (emblaApi) {
			emblaApi.on("select", () => {
				setCurrentIndex(emblaApi.selectedScrollSnap());
			});
		}
	}, [emblaApi]);

	useEffect(() => {
		setTabbarVisible(false);

		return () => {
			setTabbarVisible(true);
		};
	}, [setTabbarVisible]);

	const handlePrevImage = useCallback(() => {
		if (emblaApi) emblaApi.scrollPrev();
	}, [emblaApi]);

	const handleNextImage = useCallback(() => {
		if (emblaApi) emblaApi.scrollNext();
	}, [emblaApi]);

	if (!item.result || item.result.length === 0) {
		console.error("ResultImageViewer: No images provided");
		return null;
	}

	const styles = {
		container: {
			position: "relative" as const,
			width: "100%",
			height: "calc(100vh - var(--safe-area-inset-bottom))",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			zIndex: 1,
			backgroundColor: "#000",
		},
		viewport: {
			overflow: "hidden",
			width: "100%",
			height: "100%",
		},
		container_inner: {
			display: "flex",
			height: "100%",
			userSelect: "none" as const,
			WebkitUserSelect: "none" as const,
		},
		slide: {
			position: "relative" as const,
			flex: "0 0 100%",
			height: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		image: {
			width: "100%",
			height: "100%",
			objectFit: "contain" as const,
		},
		closeButton: {
			width: "42px",
			height: "42px",
			border: "none",
			borderRadius: "50%",
			padding: "9px",
			backdropFilter: "blur(10px)",
			WebkitBackdropFilter: "blur(10px)",
			backgroundColor: "rgba(0, 0, 0, 0.35)",
			color: "white",
			cursor: "pointer",
			transition: "background-color 0.2s",
		},
		headerButtons: {
			position: "absolute" as const,
			top: "16px",
			left: "16px",
			right: "16px",
			display: "flex",
			flexDirection: "row" as const,
			justifyContent: "space-between",
			zIndex: 100,
		},
	};

	return (
		<div style={styles.container}>
			<div style={styles.headerButtons}>
				<IconButton
					size="s"
					mode="bezeled"
					onClick={onClose}
					style={styles.closeButton}
					onMouseOver={(e) =>
						(e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.5)")
					}
					onMouseOut={(e) =>
						(e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.35)")
					}
					aria-label={t("resultImageViewer.actions.close")}
				>
					<IconClose />
				</IconButton>
				<IconButton
					size="s"
					mode="bezeled"
					onClick={onSaveButtonClick}
					style={styles.closeButton}
					onMouseOver={(e) =>
						(e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.5)")
					}
					onMouseOut={(e) =>
						(e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.35)")
					}
				>
					<IconDownload />
				</IconButton>
			</div>

			<div style={styles.viewport} ref={emblaRef}>
				<div style={styles.container_inner}>
					{item.result.map((image, index) => (
						<div key={image.photo_id} style={styles.slide}>
							<CachedImage
								src={image.url}
								alt={`Image ${index + 1}`}
								style={styles.image}
								draggable={false}
							/>
						</div>
					))}
				</div>
			</div>

			<div
				style={{
					position: "absolute",
					bottom: "16px",
					left: "16px",
					right: "16px",
					zIndex: 100,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "16px",
				}}
			>
				<CompactPagination mode="ambient">
					{item.result.map((result, index) => (
						<CompactPaginationItem
							key={result.photo_id}
							onClick={() => scrollTo(index)}
							selected={index === currentIndex}
						>
							{index + 1}
						</CompactPaginationItem>
					))}
				</CompactPagination>

				<div
					style={{
						flexGrow: 1,
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignSelf: "stretch",
						gap: "16px",
					}}
				>
					<IconButton
						size="s"
						mode="bezeled"
						onClick={handlePrevImage}
						disabled={currentIndex === 0}
						style={{
							...styles.closeButton,
							visibility: currentIndex === 0 ? "hidden" : "visible",
						}}
						onMouseOver={(e) =>
							(e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.5)")
						}
						onMouseOut={(e) =>
							(e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.35)")
						}
					>
						<IconChevronLeft />
					</IconButton>

					<Button
						mode="filled"
						size="s"
						onClick={shareStory}
						before={<IconStory />}
						style={{
							width: "250px",
						}}
					>
						{t("resultImageViewer.shareToStories")}
					</Button>

					<IconButton
						size="s"
						mode="bezeled"
						onClick={handleNextImage}
						disabled={currentIndex === (item.result?.length ?? 0) - 1}
						style={{
							...styles.closeButton,
							visibility:
								currentIndex === (item.result?.length ?? 0) - 1
									? "hidden"
									: "visible",
						}}
						onMouseOver={(e) =>
							(e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.5)")
						}
						onMouseOut={(e) =>
							(e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.35)")
						}
					>
						<IconChevronRight />
					</IconButton>
				</div>
			</div>
		</div>
	);
};

export default ResultImageViewer;
