import WebApp from "@twa-dev/sdk";

const initDataRaw = WebApp.initData;
const BASE_URL =
	"https://stickapp-backend-461697090571.europe-west3.run.app/api";

const MAX_RETRIES = 3;
const INITIAL_RETRY_DELAY = 500; // ms

interface FetchOptions extends RequestInit {
	retries?: number;
	retryDelay?: number;
}

async function fetchWithRetry(
	url: string,
	options: FetchOptions = {},
): Promise<Response> {
	const {
		retries = MAX_RETRIES,
		retryDelay = INITIAL_RETRY_DELAY,
		...fetchOptions
	} = options;

	try {
		const response = await fetch(url, fetchOptions);

		// If the request was successful or it's not a server error, return it
		if (response.ok || response.status < 500) {
			return response;
		}

		// If we have retries left and it's a server error, retry
		if (retries > 0) {
			// Exponential backoff
			const delay = retryDelay * 2 ** (MAX_RETRIES - retries);
			await new Promise((resolve) => setTimeout(resolve, delay));

			return fetchWithRetry(url, {
				...options,
				retries: retries - 1,
				retryDelay,
			});
		}

		return response;
	} catch (error) {
		// Network error, retry if possible
		if (retries > 0) {
			const delay = retryDelay * 2 ** (MAX_RETRIES - retries);
			await new Promise((resolve) => setTimeout(resolve, delay));

			return fetchWithRetry(url, {
				...options,
				retries: retries - 1,
				retryDelay,
			});
		}

		throw error;
	}
}

export const api = {
	async get<T>(
		path: string,
		options: RequestInit = {},
	): Promise<{ data: T; status: number }> {
		const url = `${BASE_URL}${path}`;
		const response = await fetchWithRetry(url, {
			...options,
			method: "GET",
			headers: {
				"X-Auth": initDataRaw,
				"Content-Type": "application/json",
				...(options.headers || {}),
			},
		});

		const data = await response.json();
		return { data, status: response.status };
	},

	async post<T>(
		path: string,
		body?: unknown,
		options: RequestInit = {},
	): Promise<{ data: T; status: number }> {
		const url = `${BASE_URL}${path}`;

		// If body is FormData, don't stringify it and let browser set content-type
		const isFormData = body instanceof FormData;
		const headers = {
			"X-Auth": initDataRaw,
			...(isFormData ? {} : { "Content-Type": "application/json" }),
			...(options.headers || {}),
		};

		const response = await fetchWithRetry(url, {
			...options,
			method: "POST",
			headers,
			body: isFormData ? body : JSON.stringify(body),
		});

		const data = await response.json();
		return { data, status: response.status };
	},
};
