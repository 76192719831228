import React from "react";

const IconDownload: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2V15.3358L18.7929 9.54289C19.1834 9.15237 19.8166 9.15237 20.2071 9.54289C20.5976 9.93342 20.5976 10.5666 20.2071 10.9571L12.7071 18.4571C12.3166 18.8476 11.6834 18.8476 11.2929 18.4571L3.79289 10.9571C3.40237 10.5666 3.40237 9.93342 3.79289 9.54289C4.18342 9.15237 4.81658 9.15237 5.20711 9.54289L11 15.3358V2ZM3.5 21C2.94772 21 2.5 21.4477 2.5 22C2.5 22.5523 2.94771 23 3.5 23H20.5C21.0523 23 21.5 22.5523 21.5 22C21.5 21.4477 21.0523 21 20.5 21H3.5Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default IconDownload;
