import { AppRoot, Link, Snackbar } from "@telegram-apps/telegram-ui";
import type React from "react";
import { useEffect, useMemo } from "react";
import WebSocketService from "./api/websocket";
import NavigationStack from "./components/NavigationStack/NavigationStack";
import TabBar from "./components/TabBar/TabBar";
import useStore from "./hooks/useStore";
import Discover from "./screens/Discover/Discover";
import Profile from "./screens/Profile/Profile";
import { TabType } from "./types/tabs";
import ReactGA from "react-ga4";

import WebApp from "@twa-dev/sdk";
import { useGalleryStore } from "./screens/StickersPicker/useGalleryStore";
import { fetchExamples } from "./utils/fetchExamples";
import { IconStories } from "./icons/IconStories";
import { useTranslation } from "react-i18next";

const App: React.FC = () => {
	const { activeTab, isTabbarVisible, setActiveTab } = useStore();
	const launchParams = useMemo(() => WebApp.initDataUnsafe, []);

	const { t } = useTranslation();

	const { fetchGallery, isNewResults, setIsNewResults } = useGalleryStore();

	useEffect(() => {
		ReactGA.initialize("G-ZR6YNN7R3N", {
			gaOptions: {
				userId: launchParams.user?.id,
				page: "discover-root",
			},
		});
	}, [launchParams]);

	useEffect(() => {
		const url = new URL(window.location.href);
		const startParam = url.searchParams.get("startapp");

		const start = startParam || launchParams.start_param;

		if (start === "results") {
			setActiveTab(TabType.PROFILE);
		}
	}, [launchParams.start_param, setActiveTab]);

	useEffect(() => {
		const handler = () => {
			const heightDiv = document.createElement("div");
			heightDiv.style.width = "1px";
			heightDiv.style.height = "100vh";
			heightDiv.style.position = "fixed";
			heightDiv.style.left = "0";
			heightDiv.style.top = "0";
			heightDiv.style.bottom = "0";
			heightDiv.style.visibility = "hidden";
			document.body.appendChild(heightDiv);
			const ch = heightDiv.clientHeight;
			heightDiv.remove();
			document.documentElement.style.setProperty("--lvh", `${ch}px`);
		};

		handler();

		window.addEventListener("resize", handler);

		return () => window.removeEventListener("resize", handler);
	}, []);

	useEffect(() => {
		const initDataRaw = WebApp.initData;

		const wsService = WebSocketService.getInstance();

		if (!wsService.isConnected()) {
			wsService.connect(`https://ws.stickersgen.online/ws?${initDataRaw}`);
		}

		const handleTaskUpdate = (data: { task_id: number; status: string }) => {
			if (data.status === "done") {
				setIsNewResults(true);

				setTimeout(() => {
					setIsNewResults(false);
				}, 8_000);
			}
		};

		wsService.on("task_status", handleTaskUpdate);

		return () => {
			wsService.off("task_status", handleTaskUpdate);
		};
	}, [activeTab, setActiveTab]);

	useEffect(() => {
		WebApp.ready();
		WebApp.expand();

		WebApp.disableVerticalSwipes();
	}, []);

	useEffect(() => {
		fetchGallery();
		fetchExamples();
	}, [fetchGallery]);

	useEffect(() => {
		const handler = () => {
			fetchGallery();
		};

		const interval = setInterval(handler, 10_000);

		return () => clearInterval(interval);
	}, [fetchGallery]);

	const renderContent = () => {
		switch (activeTab) {
			case TabType.DISCOVER:
				return (
					<NavigationStack tab={TabType.DISCOVER} rootComponent={Discover} />
				);
			case TabType.STICKERS:
				return (
					<NavigationStack tab={TabType.STICKERS} rootComponent={Discover} />
				);
			case TabType.PROFILE:
				return (
					<NavigationStack tab={TabType.PROFILE} rootComponent={Profile} />
				);
			default:
				return null;
		}
	};

	return (
		<AppRoot>
			{renderContent()}
			{isTabbarVisible && <TabBar />}

			{isNewResults && (
				<Snackbar
					before={<IconStories />}
					onClose={() => setIsNewResults(false)}
					after={
						<Snackbar.Button
							onClick={() => {
								setIsNewResults(false);
								setActiveTab(TabType.PROFILE);
							}}
						>
							{t("snackbar.open")}
						</Snackbar.Button>
					}
				>
					{t("snackbar.newResults")}
				</Snackbar>
			)}
		</AppRoot>
	);
};

export default App;
