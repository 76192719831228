import { Spinner } from "@telegram-apps/telegram-ui";
import type { FC } from "react";
import { useEffect } from "react";
import type { InferenceModel } from "../../api/types";
import { ClosableModal } from "../../components/ClosableModal/ClosableModal";
import { useNavigation } from "../../hooks/useLocation";
import { TabType } from "../../types/tabs";
import TopUp from "../TopUp/TopUp";
import DiscoverHeader from "./DiscoverHeader";
import DiscoverItem from "./DiscoverItem";
import { useDiscoverStore } from "./useDiscoverStore";
import { hapticClick } from "../../utils/haptic";
import { flushSync } from "react-dom";
import { DiscoverCategory } from "./DiscoverCategory";

const STYLE_ANIME_80S = 19;
const STYLE_SHREK = 17;
const STYLE_ANTIMAG = 31;

const STYLE_ORDER = [STYLE_ANIME_80S, STYLE_SHREK, STYLE_ANTIMAG];

const Discover: FC = () => {
	const { activeTab } = useNavigation();

	const discoverStore = useDiscoverStore();

	const isSticker = activeTab === TabType.STICKERS;

	const {
		isInitialDataReady,
		models,
		profile,
		modelsError,
		profileError,
		isTopUpModalOpen,
		fetchInitialData,
		fetchModels,
		openTopUpModal,
		closeTopUpModal,
		handleTopUpComplete,
		category,
		categories,
	} = discoverStore;

	const filteredModels = models
		?.filter(
			(model) =>
				(isSticker
					? ["meme", "img2img"].includes(model.type)
					: model.type === "photo") &&
				((isSticker ? category.meme?.id : category.photo?.id) === 0 ||
					model.category_name ===
						(isSticker ? category.meme?.name : category.photo?.name)),
		)
		.sort((a, b) => {
			const aIndex = STYLE_ORDER.indexOf(a.id);
			const bIndex = STYLE_ORDER.indexOf(b.id);

			// If both models are in the STYLE_ORDER array, sort by their order
			if (aIndex !== -1 && bIndex !== -1) {
				return aIndex - bIndex;
			}

			// If only one model is in the STYLE_ORDER array, prioritize it
			if (aIndex !== -1) return -1;
			if (bIndex !== -1) return 1;

			// Sort models to prioritize img2img type
			if (a.type === "img2img" && b.type !== "img2img") {
				return -1; // a comes before b
			}

			if (a.type !== "img2img" && b.type === "img2img") {
				return 1; // b comes before a
			}

			// If all else is equal, maintain original order
			return 0;
		});

	useEffect(() => {
		fetchInitialData();
	}, [fetchInitialData]);

	useEffect(() => {
		fetchModels();
	}, [fetchModels]);

	const renderContent = () => {
		if (!isInitialDataReady) {
			return (
				<div
					className="container h-screen"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "calc(100vh - var(--tab-bar-height))",
					}}
				>
					<Spinner size="l" />
				</div>
			);
		}

		if (modelsError) {
			return (
				<div className="tgui-text-error">
					Error: {modelsError.message}
					{modelsError.status && <div>Status: {modelsError.status}</div>}
				</div>
			);
		}

		if (profileError) {
			return (
				<div className="tgui-text-error">
					Error: {profileError.message}
					{profileError.status && <div>Status: {profileError.status}</div>}
				</div>
			);
		}

		return (
			<>
				<DiscoverHeader
					onTopUp={() => {
						flushSync(() => {
							hapticClick("light");
							openTopUpModal();
						});
					}}
				/>
				<DiscoverCategory />
				{filteredModels?.map((model: InferenceModel) => (
					<DiscoverItem key={model.id} style={model} isSticker={isSticker} />
				))}
			</>
		);
	};

	return (
		<div className="container enable-scrollbar mb-[var(--tab-bar-height)]">
			<ClosableModal
				isOpen={isTopUpModalOpen}
				onOpenChange={closeTopUpModal}
				bgSecondary={true}
			>
				<TopUp
					balance={profile?.balance ?? 0}
					onClose={(e) => {
						hapticClick("light");
						handleTopUpComplete(e);
					}}
				/>
			</ClosableModal>
			{renderContent()}
		</div>
	);
};

export default Discover;
