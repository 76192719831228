import { Button, Caption, Text, Title } from "@telegram-apps/telegram-ui";
import { useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { Animation } from "../../components/Animation";
import { formatter } from "../../helpers/formatter";
import IconBolt from "../../icons/IconBolt";
import { useDiscoverStore } from "../Discover/useDiscoverStore";
import { ClosableModal } from "../../components/ClosableModal/ClosableModal";
import { hapticClick } from "../../utils/haptic";
import TopUp from "../TopUp/TopUp";
import WebApp from "@twa-dev/sdk";

interface GiftProps {
	onClose?: () => void;
}

export const Gift: FC<GiftProps> = (props) => {
	const [loading, setLoading] = useState<number | null>(null);
	const [giftAmount, setGiftAmount] = useState<number>(10);

	const { t } = useTranslation();

	const {
		profile,
		sendGift,
		isTopUpModalOpen,
		openTopUpModal,
		closeTopUpModal,
		handleTopUpComplete,
		fetchInitialData,
	} = useDiscoverStore();

	const balance = profile?.balance || 0;

	const gift = async (amount: number) => {
		setLoading(amount);
		const url = await sendGift(amount);

		console.log("url", url);

		if (url) {
			const shareText = t("giftModal.shareText", { count: amount });

			WebApp.openTelegramLink(
				`https://t.me/share/url?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(url)}`,
			);
			props.onClose?.();
		}

		fetchInitialData();

		setLoading(null);
	};

	const handleGiftClick = (amount: number) => {
		const isAvailable = amount <= balance;

		if (!isAvailable) {
			setGiftAmount(amount);
			openTopUpModal();
		} else {
			gift(amount);
		}
	};

	const amounts = [10, 30, 60, 100, 500];

	return (
		<div>
			<ClosableModal
				isOpen={isTopUpModalOpen}
				onOpenChange={closeTopUpModal}
				bgSecondary={true}
			>
				<TopUp
					balance={balance}
					onClose={(e) => {
						hapticClick("light");
						handleTopUpComplete(e);

						if (e) {
							gift(giftAmount);
						}
					}}
				/>
			</ClosableModal>
			<div
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					maxWidth: "100vw",
					padding: "16px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<Caption
						weight="3"
						style={{ lineHeight: "16px", letterSpacing: "-0.08px" }}
					>
						{t("topUp.balance")}
					</Caption>
					<div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
						<IconBolt width={12} height={18} />
						<Text
							weight="1"
							style={{
								fontSize: "17px",
								lineHeight: "22px",
								letterSpacing: "-0.4px",
							}}
						>
							{formatter.format(balance)}
						</Text>
					</div>
				</div>
			</div>
			<div
				style={{
					paddingTop: "30px",
					paddingBottom: "16px",
					paddingLeft: "16px",
					paddingRight: "16px",
					display: "flex",
					gap: "24px",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						gap: "8px",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Animation
						animationId="unicorn"
						style={{
							width: "64px",
							height: "82px",
							minWidth: "64px",
							minHeight: "82px",
						}}
					/>
					<Title level="2" weight="1">
						{t("giftModal.title")}
					</Title>
					<div className="text-center items-center font-regular text-[15px] leading-[20px]">
						{t("giftModal.description_0")}{" "}
						<IconBolt width={10} height={14.12} className="inline-block" />{" "}
						{t("giftModal.description_1")}
					</div>
					<div className="flex flex-wrap w-full gap-2 justify-center mt-[24px]">
						{amounts.map((amount) => (
							<Button
								key={amount}
								onClick={() => handleGiftClick(amount)}
								size="m"
								mode={"filled"}
								className="!bg-background w-[100px]"
								loading={loading === amount}
							>
								<Text className="!text-text">
									<IconBolt
										width={10}
										height={14.12}
										className="inline-block"
									/>{" "}
									{amount}
								</Text>
							</Button>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
