import { Modal } from "@telegram-apps/telegram-ui";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import React, { useEffect, useState } from "react";
import "./ClosableModal.css";

interface ClosableModalProps {
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
	children: React.ReactNode;
	bgSecondary?: boolean;
}

export const ClosableModal: React.FC<ClosableModalProps> = ({
	isOpen,
	onOpenChange,
	children,
	bgSecondary = false,
}) => {
	return (
		<Modal
			dismissible
			header={
				<ModalHeader
					after={
						<ModalClose>
							<Icon28Close
								style={{
									color: "var(--tgui--hint_color)",
									cursor: "pointer",
								}}
							/>
						</ModalClose>
					}
				/>
			}
			open={isOpen}
			onOpenChange={onOpenChange}
			className={`tg-ui-modal-fix ${bgSecondary ? "tg-ui-modal-fix--secondary_bg_color" : ""}`}
		>
			{children}
		</Modal>
	);
};
