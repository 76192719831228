import React from "react";

const IconClose: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.50159 4.4402C4.85072 4.08641 5.42056 4.08263 5.77435 4.43176L11.9997 10.5751L18.2251 4.43176C18.5788 4.08263 19.1487 4.08641 19.4978 4.4402C19.847 4.79399 19.8432 5.36383 19.4894 5.71296L13.281 11.8396L19.4894 17.9662C19.8432 18.3153 19.847 18.8852 19.4978 19.239C19.1487 19.5928 18.5788 19.5965 18.2251 19.2474L11.9997 13.104L5.77435 19.2474C5.42056 19.5965 4.85072 19.5928 4.50159 19.239C4.15245 18.8852 4.15623 18.3153 4.51002 17.9662L10.7184 11.8396L4.51002 5.71296C4.15623 5.36383 4.15245 4.79399 4.50159 4.4402Z"
				fill="currentColor"
				stroke="currentColor"
				strokeWidth="0.4"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default IconClose;
